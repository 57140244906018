import DiModule from "../../../lib/di/DiModule"
import DefaultCurrentUserRepository from "../../data/repositories/DefaultCurrentUserRepository"
import DefaultSubscriptionsRepository from "../../../subscriptions/data/DefaultSubscriptionsRepository"
import CurrentUserRepository from "../../domain/repositories/CurrentUserRepository"
import SubscriptionsRepository from "../../../subscriptions/domain/repositories/SubscriptionsRepository"
import DefaultSessionsRepository from "../../data/repositories/DefaultSessionsRepository"
import SessionsRepository from "../../domain/repositories/SessionsRepository"
import CoreCurrentUserNetworkDiModule from "./CoreCurrentUserNetworkDiModule"
import CoreLocalDiModule from "./CoreLocalDiModule"
import CoreSessionsNetworkDiModule from "./CoreSessionsNetworkDiModule"
import CoreSubscriptionsNetworkDiModule from "./CoreSubscriptionsNetworkDiModule"

export default interface CoreDataDiModule {
  provideSessionsRepository(): SessionsRepository

  provideSubscriptionsRepository(): SubscriptionsRepository

  provideCurrentUserRepository(): CurrentUserRepository
}

export class DefaultCoreDataDiModule extends DiModule implements CoreDataDiModule {
  private readonly coreLocalDiModule: CoreLocalDiModule
  private readonly coreSessionsNetworkSourceDiModule: CoreSessionsNetworkDiModule
  private readonly coreSubscriptionsNetworkSourceDiModule: CoreSubscriptionsNetworkDiModule
  private readonly coreCurrentUserNetworkSourceDiModule: CoreCurrentUserNetworkDiModule

  constructor(parameters: {
    readonly coreLocalDiModule: CoreLocalDiModule
    readonly coreSessionsNetworkSourceDiModule: CoreSessionsNetworkDiModule
    readonly coreSubscriptionsNetworkSourceDiModule: CoreSubscriptionsNetworkDiModule
    readonly coreCurrentUserNetworkSourceDiModule: CoreCurrentUserNetworkDiModule
  }) {
    super()
    this.coreLocalDiModule = parameters.coreLocalDiModule
    this.coreSessionsNetworkSourceDiModule = parameters.coreSessionsNetworkSourceDiModule
    this.coreSubscriptionsNetworkSourceDiModule = parameters.coreSubscriptionsNetworkSourceDiModule
    this.coreCurrentUserNetworkSourceDiModule = parameters.coreCurrentUserNetworkSourceDiModule
  }

  provideSessionsRepository(): SessionsRepository {
    return this.single(
      DefaultSessionsRepository.name,
      () => new DefaultSessionsRepository({
        sessionLocalSource: this.coreLocalDiModule.provideSessionLocalSource(),
        sessionNetworkSource: this.coreSessionsNetworkSourceDiModule
          .provideSessionNetworkSource()
      })
    )
  }

  provideSubscriptionsRepository(): SubscriptionsRepository {
    return this.single(
      DefaultSubscriptionsRepository.name,
      () => new DefaultSubscriptionsRepository({
        subscriptionsNetworkSource: this.coreSubscriptionsNetworkSourceDiModule
          .provideSubscriptionsNetworkSource()
      })
    )
  }

  provideCurrentUserRepository(): CurrentUserRepository {
    return this.single(
      DefaultCurrentUserRepository.name,
      () => new DefaultCurrentUserRepository({
        currentUserNetworkSource: this.coreCurrentUserNetworkSourceDiModule
          .provideCurrentUserNetworkSource()
      })
    )
  }
}
