import NetworkAttributeError from "../errors/NetworkAttributeError"
import { Expose, Type } from "class-transformer"
import DomainNetworkCreateSessionErrorsObjectAttributes
  from "../../../../domain/entities/network/sessions/NetworkCreateSessionErrorsObjectAttributes"

export default class NetworkCreateSessionErrorsObjectAttributes
implements DomainNetworkCreateSessionErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly authToken?: NetworkAttributeError[] | null
}
