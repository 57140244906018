import SubscriptionsRepository from "../../domain/repositories/SubscriptionsRepository"
import CurrentUserRepository from "../../../app/domain/repositories/CurrentUserRepository"
import DiModule from "../../../lib/di/DiModule"
import CoreDataDiModule from "../../../app/di/modules/CoreDataDiModule"

export default interface SubscriptionsDataDiModule {
  provideSubscriptionsRepository(): SubscriptionsRepository

  provideCurrentUserRepository(): CurrentUserRepository
}

export class DefaultSubscriptionsDataDiModule
  extends DiModule
  implements SubscriptionsDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  provideSubscriptionsRepository(): SubscriptionsRepository {
    return this.coreDataDiModule.provideSubscriptionsRepository()
  }

  provideCurrentUserRepository(): CurrentUserRepository {
    return this.coreDataDiModule.provideCurrentUserRepository()
  }
}
