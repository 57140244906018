import DiModule from "../../../lib/di/DiModule"
import CoreCurrentUserNetworkDiModule from "./CoreCurrentUserNetworkDiModule"
import CurrentUserRepository from "../../domain/repositories/CurrentUserRepository"
import DefaultCurrentUserRepository from "../../data/repositories/DefaultCurrentUserRepository"

export default interface CurrentUserDataDiModule {
  provideCurrentUserRepository(): CurrentUserRepository
}

export class DefaultCurrentUserDataDiModule extends DiModule implements CurrentUserDataDiModule {
  private readonly currentUserNetworkDiModule: CoreCurrentUserNetworkDiModule

  constructor(parameters: {
    readonly currentUserNetworkDiModule: CoreCurrentUserNetworkDiModule
  }) {
    super()
    this.currentUserNetworkDiModule = parameters.currentUserNetworkDiModule
  }

  provideCurrentUserRepository(): CurrentUserRepository {
    return this.single(
      DefaultCurrentUserRepository.name,
      () => new DefaultCurrentUserRepository({
        currentUserNetworkSource: this.currentUserNetworkDiModule.provideCurrentUserNetworkSource()
      })
    )
  }
}
