import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import SubscriptionsNetworkSource, {
  CancelSubscriptionNetworkParameters,
  CancelSubscriptionNetworkResult,
  GetSubscriptionNetworkParameters,
  GetSubscriptionNetworkResult,
  GetSubscriptionsNetworkResult,
  PurchaseSubscriptionNetworkParameters,
  PurchaseSubscriptionNetworkResult
} from "./SubscriptionsNetworkSource"
import NetworkSubscription from "../../entities/network/subscriptions/NetworkSubscription"
import NetworkExecutionError from "../../entities/network/errors/NetworkExecutionError"
import NetworkSubscriptionsResponseBody from "../../entities/network/subscriptions/response-bodies/NetworkSubscriptionsResponseBody"
import NetworkSubscriptionResponseBody from "../../entities/network/subscriptions/response-bodies/NetworkSubscriptionResponseBody"
import NetworkPurchaseSubscriptionResponseBody from "../../entities/network/subscriptions/response-bodies/NetworkPurchaseSubscriptionResponseBody"
import NetworkCancelSubscriptionResponseBody from "../../entities/network/subscriptions/response-bodies/NetworkCancelSubscriptionResponseBody"

const basePath = "/api/v1/client/subscriptions"

export default class DefaultSubscriptionsNetworkSource implements SubscriptionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getSubscription(
    parameters: GetSubscriptionNetworkParameters
  ): Promise<GetSubscriptionNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.GET,
        path: `${basePath}/${parameters.id}`
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkSubscription = plainToInstance(
          NetworkSubscriptionResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkSubscription
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }

  async getSubscriptions(): Promise<GetSubscriptionsNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.GET,
        path: basePath
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkSubscriptions = plainToInstance(
          NetworkSubscriptionsResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkSubscriptions
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }

  async cancelSubscription(parameters: CancelSubscriptionNetworkParameters): Promise<CancelSubscriptionNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.DELETE,
        path: `${basePath}/${parameters.id}/cancel`
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkSubscription = plainToInstance(
          NetworkCancelSubscriptionResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkSubscription
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }

  async purchaseSubscription(
    parameters: PurchaseSubscriptionNetworkParameters
  ): Promise<PurchaseSubscriptionNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.POST,
        path: `${basePath}/${parameters.id}/purchase`
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkSubscription: NetworkSubscription = plainToInstance(
          NetworkPurchaseSubscriptionResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkSubscription
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }
}
