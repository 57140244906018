import { GetProfileResult } from "../../../domain/use-cases/profile/GetProfileUseCase"

export default class ProfileNetworkSource {
  async getProfile(): Promise<GetProfileResult> {
    // TODO: unmock the mock
    return {
      type: "success",
      profile: {
        id: 42
      }
    }
  }
}
