import ExecutionError from "../../entities/ExecutionError"
import Subscription from "../../entities/Subscription"
import { UpdateObjectResult } from "../../../../objects/domain/results/UpdateObjectResult"
import CurrentUserRepository from "../../repositories/CurrentUserRepository"

export default class UpdateCurrentUserUseCase {
  private readonly currentUserRepository: CurrentUserRepository

  constructor(parameters: {
    readonly currentUserRepository: CurrentUserRepository
  }) {
    this.currentUserRepository = parameters.currentUserRepository
  }

  async call(parameters: UpdateCurrentUserParameters): Promise<UpdateCurrentUserResult> {
    return await this.currentUserRepository.updateCurrentUser(parameters)
  }
}

export interface UpdateCurrentUserParameters {
  readonly email?: string | null
}

export type UpdateCurrentUserResult = UpdateObjectResult<Subscription, ExecutionError>
