import React, { useEffect, useRef, useState } from "react"
import styles from "./TextInputComponent.module.scss"
import isBlank from "../isBlank"
import typography from "../../ui_kit/typography.module.scss"

export enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  NUMBER = "number"
}

export function TextInputComponent({
  name,
  className,
  type = TextInputType.TEXT,
  value = "",
  isDisabled = false,
  hasErrors = false,
  isTextAllCaps = false,
  placeholder = "",
  onChange = () => undefined,
  onKeyDown = () => undefined,
  onClick = () => undefined
}: {
  readonly name?: string
  readonly value?: string | null
  readonly type?: TextInputType
  readonly isDisabled?: boolean
  readonly isTextAllCaps?: boolean
  readonly placeholder?: string
  readonly hasErrors?: boolean
  readonly className?: string
  readonly onChange?: (value: string) => void
  readonly onKeyDown?: (key: React.KeyboardEvent<HTMLInputElement>) => void
  readonly onClick?: () => void
}) {
  const [inputText, setInputText] = useState(value)
  const [cursorPosition, setCursorPosition] = useState(0)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isBlank(inputRef.current) || type === TextInputType.NUMBER || type === TextInputType.EMAIL) return

    inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
  }, [inputText])

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value, selectionStart } } = event
    setCursorPosition(selectionStart ?? 0)
    const formattedValue = isTextAllCaps ? value.toUpperCase() : value
    setInputText(formattedValue)
    onChange(formattedValue)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <input
        id={name}
        ref={inputRef}
        className={`${styles.input} ${typography.body2} ${hasErrors ? styles.hasErrors : ""}`}
        type={type}
        value={value ?? ""}
        placeholder={placeholder}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onChange={handleOnChange}
        disabled={isDisabled}
      />
    </div>
  )
}

export default TextInputComponent
