export default class DiModule {
  private singleByKey: Map<string, unknown> = new Map<string, unknown>()

  protected single<Type>(key: string, getter: () => Type): Type {
    let value = this.singleByKey.get(key) as Type

    if (value === undefined) {
      value = getter()
      this.singleByKey.set(key, value)
    }

    return value
  }
}
