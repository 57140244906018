import React from "react"
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from "react-router-dom"
import { createDi } from "./Di"
import UrlProvider from "./UrlProvider"
import { CoreTextProviderProvider } from "./presentation/contexts/CoreTextProviderContext"
import { objectIdUrlPart } from "./presentation/hooks/useObjectId"

const urlProvider = new UrlProvider()
const di = createDi()

const router = createBrowserRouter([
  {
    element: di.provideRootPage(),
    children: [
      ...buildRootRoutes(),
      ...buildSubscriptionsRoutes(),
      ...buildErrorPagesRoutes()
    ]
  }
])

function App() {
  return (
    <CoreTextProviderProvider coreI18n={di.coreDiComponent.provideCoreI18n()}>
      <RouterProvider router={router} />
    </CoreTextProviderProvider>
  )
}

export default App

function buildRootRoutes(): RouteObject[] {
  return [
    {
      path: urlProvider.buildRootUrl(),
      element: <Navigate to={urlProvider.buildSubscriptionsUrl()} replace={true} />
    }
  ]
}

function buildErrorPagesRoutes(): RouteObject[] {
  return [
    {
      path: urlProvider.buildForbiddenUrl(),
      element: di.provideForbiddenPage()
    },
    {
      path: "*",
      element: di.provideNotFoundPage()
    }
  ]
}

function buildSubscriptionsRoutes(): RouteObject[] {
  return [
    {
      path: urlProvider.buildSubscriptionsUrl(),
      element: di.provideSubscriptionsListPage()
    },
    {
      path: urlProvider.buildSubscriptionUrl({ id: `:${objectIdUrlPart}` }),
      element: di.provideSubscriptionPage()
    }
  ]
}
