import User from "../../entities/User"
import { GetObjectResult } from "../../../../objects/domain/results/GetObjectResult"
import CurrentUserRepository from "../../repositories/CurrentUserRepository"

export default class GetCurrentUserUseCase {
  private readonly currentUserRepository: CurrentUserRepository

  constructor(parameters: {
    readonly currentUserRepository: CurrentUserRepository
  }) {
    this.currentUserRepository = parameters.currentUserRepository
  }

  async call(): Promise<GetCurrentUserResult> {
    return await this.currentUserRepository.getCurrentUser()
  }
}

export type GetCurrentUserResult = GetObjectResult<User>
