import Chat from "../../../domain/entities/Chat"
import NetworkChat from "../../entities/network/subscriptions/NetworkChat"

export default class ChatsMapper {
  mapNetworkToDomain({
    chat
  }: {
    readonly chat: NetworkChat
  }): Chat {
    return {
      id: chat.id,
      name: chat.name,
      subscriptionPrice: chat.subscriptionPrice,

      // TODO: do we need other info about the cover?
      chatCoverUrl: chat?.chatCover?.file?.urls?.icon
    }
  }
}
