import NetworkProfile from "../../entities/profile/NetworkProfile"
import Profile from "../../../domain/entities/Profile"

export default class ProfilesMapper {
  mapNetworkToDomain({
    profile
  }: {
    readonly profile: NetworkProfile
  }): Profile {
    return {
      id: profile.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      iconUrl: profile.image?.file?.urls?.icon
    }
  }
}
