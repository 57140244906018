import React from "react"
import styles from "./ErrorMessageComponent.module.scss"
import TextComponent, { TextStyle } from "../text/TextComponent"

export default function ErrorMessageComponent({
  text,
  retryText,
  messageTextStyle,
  buttonTextStyle,
  onClick
}: {
  readonly text?: string | null | undefined
  readonly retryText?: string | null | undefined
  readonly messageTextStyle: TextStyle
  readonly buttonTextStyle: TextStyle
  readonly onClick?: () => void
}) {
  return (
    <>
      <div className={styles.errorMessageContainer} onClick={onClick}>
        <TextComponent textStyle={messageTextStyle}>{text}</TextComponent>
        {retryText && <TextComponent textStyle={buttonTextStyle}>{retryText}</TextComponent>}
      </div>
    </>
  )
}
