import DiModule from "../../../lib/di/DiModule"
import GetCurrentUserUseCase from "../../domain/use-cases/current-user/GetCurrentUserUseCase"
import CurrentUserDataDiModule from "./CurrentUserDataDiModule"

export default interface CurrentUserDomainDiModule {
  provideGetCurrentUserUseCase(): GetCurrentUserUseCase
}

export class DefaultCurrentUserDomainDiModule extends DiModule implements CurrentUserDomainDiModule {
  private readonly currentUserDataDiModule: CurrentUserDataDiModule

  constructor(parameters: {
    currentUserDataDiModule: CurrentUserDataDiModule
  }) {
    super()
    this.currentUserDataDiModule = parameters.currentUserDataDiModule
  }

  provideGetCurrentUserUseCase(): GetCurrentUserUseCase {
    return this.single(
      GetCurrentUserUseCase.name,
      () => new GetCurrentUserUseCase({
        currentUserRepository: this.currentUserDataDiModule.provideCurrentUserRepository()
      })
    )
  }
}
