import Subscription from "../../../domain/entities/Subscription"
import NetworkSubscription from "../../entities/network/subscriptions/NetworkSubscription"
import ChatsMapper from "../chats/ChatsMapper"

export default class SubscriptionsMapper {
  mapNetworkToDomain({
    subscription
  }: {
    readonly subscription: NetworkSubscription
  }): Subscription {
    return {
      id: subscription.id,
      autoProlongation: subscription.autoProlongation,
      bankCardPan: subscription.bankCardPan,
      expiresAt: subscription.expiresAt,
      paymentUrl: subscription.paymentUrl,
      suspended: subscription.suspended,
      suspendReasonCode: subscription.suspendReasonCode,
      suspendReasonName: subscription.suspendReasonName,
      statusExplanation: subscription.statusExplanation,
      needsEmail: subscription.subscriptionPermissionsSet?.needsEmail,
      canPurchase: subscription.subscriptionPermissionsSet?.canPurchase,
      canSuspend: subscription.subscriptionPermissionsSet?.canSuspend,
      chat: subscription.chat && new ChatsMapper().mapNetworkToDomain({ chat: subscription.chat! })
    }
  }
}
