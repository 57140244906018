import DiModule from "../../../lib/di/DiModule"
import CoreNetworkDiModule from "./CoreNetworkDiModule"
import SessionsNetworkSource from "../../data/sources/network/SessionsNetworkSource"
import DefaultSessionNetworkSource from "../../data/sources/network/DefaultSessionNetworkSource"

export default interface CoreSessionsNetworkDiModule {
  provideSessionNetworkSource(): SessionsNetworkSource
}

export class DefaultCoreSessionsNetworkDiModule
  extends DiModule
  implements CoreSessionsNetworkDiModule {

  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  provideSessionNetworkSource(): SessionsNetworkSource {
    return this.single(
      DefaultSessionNetworkSource.name,
      () => new DefaultSessionNetworkSource({
        backendHttpClient: this.coreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
