import ExecutionError from "../../../app/domain/entities/ExecutionError"
import Subscription from "../../../app/domain/entities/Subscription"
import { UpdateObjectResult } from "../../../objects/domain/results/UpdateObjectResult"
import SubscriptionsRepository from "../repositories/SubscriptionsRepository"

export default class PurchaseSubscriptionUseCase {
  private readonly subscriptionsRepository: SubscriptionsRepository

  constructor(parameters: {
    readonly subscriptionsRepository: SubscriptionsRepository
  }) {
    this.subscriptionsRepository = parameters.subscriptionsRepository
  }

  async call(parameters: PurchaseSubscriptionParameters): Promise<PurchaseSubscriptionResult> {
    return await this.subscriptionsRepository.purchaseSubscription(parameters)
  }
}

export interface PurchaseSubscriptionParameters {
  readonly id: number
}

export type PurchaseSubscriptionResult = UpdateObjectResult<Subscription, ExecutionError>
