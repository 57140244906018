import SessionsRepository from "../../../domain/repositories/SessionsRepository"
import SuccessExecutionResult from "../../results/SuccessExecutionResult"
import ErrorExecutionResult from "../../results/ErrorExecutionResult"
import FailureExecutionResult from "../../results/FailureExecutionResult"
import ExecutionError from "../../../domain/entities/ExecutionError"
import CreateSessionErrorsObject from "../../../domain/entities/CreateSessionErrorsObject"

export default class CreateSessionUseCase {
  private readonly sessionsRepository: SessionsRepository

  constructor(parameters: {
    readonly sessionsRepository: SessionsRepository
  }) {
    this.sessionsRepository = parameters.sessionsRepository
  }

  async call(parameters: CreateSessionParameters): Promise<CreateSessionResult> {
    return await this.sessionsRepository.createSession(parameters)
  }
}

export interface CreateSessionParameters {
  readonly authToken: string
}

export interface CreateSessionError extends ExecutionError {
  readonly errorsObject?: CreateSessionErrorsObject | null
}

export type CreateSessionResult =
  SuccessExecutionResult |
  ErrorExecutionResult<CreateSessionError> |
  FailureExecutionResult
