import ObjectsRepository from "../../domain/repositories/ObjectsRepository"
import DefaultObjectsRepository from "../../data/repositories/DefaultObjectsRepository"
import DiModule from "../../../lib/di/DiModule"

export default interface ObjectsDataDiModule {
  provideObjectsRepository(parameters: { readonly key: string }): ObjectsRepository
}

export class DefaultObjectsDataDiModule
  extends DiModule
  implements ObjectsDataDiModule {

  provideObjectsRepository({ key }: { readonly key: string }): ObjectsRepository {
    return this.single(
      `${DefaultObjectsRepository.name}_${key}`,
      () => new DefaultObjectsRepository()
    )
  }
}
