import React from "react"
import styles from "./ModalComponent.module.scss"
import typography from "../../ui_kit/typography.module.scss"
import TextButtonComponent from "../../lib/TextButtonComponent/TextButtonComponent"
import Modal from "react-modal"

export interface ModalComponentProps {
  readonly appElement: HTMLElement
  readonly title?: string
  readonly text?: string
  readonly isOpen: boolean
  readonly positiveButtonTitle: string
  readonly negativeButtonTitle?: string
  readonly onPositiveButtonClicked: () => void
  readonly onRequestClosed: () => void
  readonly onNegativeButtonClicked?: () => void
}

export default function ModalComponent({
  appElement,
  title,
  text,
  isOpen,
  positiveButtonTitle,
  negativeButtonTitle,
  onPositiveButtonClicked,
  onRequestClosed,
  onNegativeButtonClicked = () => undefined
}: ModalComponentProps) {
  return (
    <Modal
      appElement={appElement}
      isOpen={isOpen}
      onRequestClose={onRequestClosed}
      className={styles.root}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content}>
        <div className={styles.textContainer}>
          {title && (
            <div className={`${styles.title} ${typography.title4}`}>
              {title}
            </div>
          )}
          {text && (
            <div className={`${styles.text} ${typography.body2}`}>
              {text}
            </div>
          )}
        </div>
        <TextButtonComponent
          onClick={onPositiveButtonClicked}
          title={positiveButtonTitle}
          className={`${styles.button}`}
        />
        {negativeButtonTitle && (
          <TextButtonComponent
            onClick={onNegativeButtonClicked}
            title={negativeButtonTitle}
            className={`${styles.button} ${styles.negative}`}
          />
        )}
      </div>
    </Modal>
  )
}
