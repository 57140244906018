import { Expose, Type } from "class-transformer"

export default class NetworkFileUrls {
  @Expose({ name: "profile" })
  @Type(() => String)
  readonly profile?: string | null

  @Expose({ name: "icon" })
  @Type(() => String)
  readonly icon?: string | null
}
