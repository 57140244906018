import SessionsRepository from "../../../domain/repositories/SessionsRepository"

export default class IsSessionExistUseCase {
  private readonly sessionsRepository: SessionsRepository

  constructor(parameters: {
    readonly sessionsRepository: SessionsRepository
  }) {
    this.sessionsRepository = parameters.sessionsRepository
  }

  call(): boolean {
    return this.sessionsRepository.isSessionExist()
  }
}
