import DiModule from "../../../lib/di/DiModule"
import CoreI18n from "../../i18n/CoreI18n"
import RuCoreTextProvider from "../../i18n/core-text-providers/RuCoreTextProvider"

export default interface CoreI18nDiModule {
  provideCoreI18n(): CoreI18n
}

export class DefaultCoreI18nModule extends DiModule implements CoreI18nDiModule {
  provideCoreI18n(): CoreI18n {
    return this.single(
      CoreI18n.name,
      () => new CoreI18n({
        language: "RU",
        textProviderByLanguage: {
          RU: new RuCoreTextProvider()
        }
      })
    )
  }
}
