import User from "../../../domain/entities/User"
import NetworkUser from "../../entities/network/users/NetworkUser"
import ProfilesMapper from "../profiles/ProfilesMapper"

export default class UsersMapper {
  mapNetworkToDomain({
    user
  }: {
    readonly user: NetworkUser
  }): User {
    return {
      id: user.id,
      email: user.email,
      profile: user.profile && new ProfilesMapper().mapNetworkToDomain({
        profile: user.profile
      })
    }
  }
}
