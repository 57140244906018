import Profile from "../../entities/Profile"
import ProfileRepository from "../../repositories/ProfileRepository"

export default class GetProfileUseCase {
  private readonly profileRepository: ProfileRepository

  constructor(parameters: {
    readonly profileRepository: ProfileRepository
  }) {
    this.profileRepository = parameters.profileRepository
  }

  async call(): Promise<GetProfileResult> {
    return await this.profileRepository.getProfile()
  }
}

export interface SuccessExecutionResult {
  type: "success",
  profile: Profile
}
export interface ErrorExecutionResult {
  type: "error",
  message: string
}
export interface FailureExecutionResult {
  type: "failure"
}

export type GetProfileResult =
  SuccessExecutionResult |
  ErrorExecutionResult |
  FailureExecutionResult
