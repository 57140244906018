import { Expose, Type } from "class-transformer"
import NetworkImage from "../images/NetworkImage"

export default class NetworkProfile {
  @Expose({ name: "id" })
  @Type(() => Number)
  readonly id?: number | null

  @Expose({ name: "first_name" })
  @Type(() => String)
  readonly firstName?: string | null

  @Expose({ name: "last_name" })
  @Type(() => String)
  readonly lastName?: string | null

  @Expose({ name: "image" })
  @Type(() => NetworkImage)
  readonly image?: NetworkImage | null
}
