import { Expose, Type } from "class-transformer"
import NetworkChat from "./NetworkChat"
import NetworkSubscriptionPermissionsSet from "./NetworkSubscriptionPermissionsSet"

export default class NetworkSubscription {
  @Expose({ name: "id" })
  @Type(() => Number)
  readonly id?: number | null

  @Expose({ name: "auto_prolongation" })
  @Type(() => Boolean)
  readonly autoProlongation?: boolean | null

  @Expose({ name: "suspended" })
  @Type(() => Boolean)
  readonly suspended?: boolean | null

  @Expose({ name: "expires_at" })
  @Type(() => Date)
  readonly expiresAt?: Date | null

  @Expose({ name: "bank_card_pan" })
  @Type(() => String)
  readonly bankCardPan?: string | null

  @Expose({ name: "suspend_reason_code" })
  @Type(() => String)
  readonly suspendReasonCode?: string | null

  @Expose({ name: "suspend_reason_name" })
  @Type(() => String)
  readonly suspendReasonName?: string | null

  @Expose({ name: "status_explanation" })
  @Type(() => String)
  readonly statusExplanation?: string | null

  @Expose({ name: "payment_url" })
  @Type(() => String)
  readonly paymentUrl?: string | null

  @Expose({ name: "subscription_permissions_set" })
  @Type(() => NetworkSubscriptionPermissionsSet)
  readonly subscriptionPermissionsSet?: NetworkSubscriptionPermissionsSet | null

  @Expose({ name: "chat" })
  @Type(() => NetworkChat)
  readonly chat?: NetworkChat | null
}
