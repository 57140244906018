import NetworkCreateSessionErrorsObjectAttributes from "./NetworkCreateSessionErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import DomainNetworkCreateSessionErrorsObject
  from "../../../../domain/entities/network/sessions/NetworkCreateSessionErrorsObject"

export default class NetworkCreateSessionErrorsObject
implements DomainNetworkCreateSessionErrorsObject {
  @Expose()
  @Type(() => NetworkCreateSessionErrorsObjectAttributes)
  readonly attributes?: NetworkCreateSessionErrorsObjectAttributes | null
}
