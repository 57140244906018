import ProfileNetworkSource from "../../../data/sources/network/ProfileNetworkSource"
import DiModule from "../../../../lib/di/DiModule"
import CoreNetworkDiModule from "../CoreNetworkDiModule"

export default interface ProfileNetworkDiModule {
  provideProfileNetworkSource(): ProfileNetworkSource
}

export class DefaultProfileNetworkDiModule extends DiModule implements ProfileNetworkDiModule {
  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  provideProfileNetworkSource(): ProfileNetworkSource {
    return this.single(
      ProfileNetworkSource.name,
      () => new ProfileNetworkSource()
    )
  }
}
