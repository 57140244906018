import autoBind from "auto-bind"
import ExecutionError from "../../../app/domain/entities/ExecutionError"
import { GetObjectResult } from "../../domain/results/GetObjectResult"
import ApplicationException from "../../../app/domain/exceptions/ApplicationException"
import assertNever from "../../../lib/assertNever"
import { StateObservable } from "../../../lib/view-model/StateObservable"
import { ObjectViewState } from "../view-states/ObjectViewState"
import BroadcastObjectsEventUseCase from "../../domain/use-cases/objects/BroadcastObjectsEventUseCase"

export default class ObjectPresentationLogic<DomainObject> {
  private readonly getObject?: GetObjectFunction<DomainObject>
  protected object?: DomainObject
  private changingException?: ApplicationException
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase

  readonly observableObjectViewState: StateObservable<ObjectViewState<DomainObject>> =
    new StateObservable<ObjectViewState<DomainObject>>({ type: "initial" })

  constructor(parameters: {
    readonly isNewObject: boolean
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getObject?: () => Promise<GetObjectResult<DomainObject>>
  }) {
    autoBind(this)
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getObject = parameters.getObject
    this.loadAndShowObject().then()
  }

  setAndShowObject({
    object
  }: {
    readonly object: DomainObject
  }) {
    this.setLoadingObjectViewState()
    this.setObject(object)
    this.setLoadedObjectViewState()
  }

  setLoadingObjectViewState() {
    this.observableObjectViewState.setValue({
      type: "loading"
    })
  }

  setLoadingWithContentViewState() {
    this.observableObjectViewState.setValue({
      type: "loading_with_content",
      object: this.object!,
      changingException: this.changingException
    })
  }

  setLoadingErrorObjectViewState({ error }: { readonly error: ExecutionError }) {
    this.observableObjectViewState.setValue({
      type: "loading_error",
      error
    })
  }

  setLoadingFailureObjectViewState({ exception }: { readonly exception: ApplicationException }) {
    this.observableObjectViewState.setValue({
      type: "loading_failure",
      exception
    })
  }

  setLoadedObjectViewState() {
    this.observableObjectViewState.setValue({
      type: "loaded",
      object: this.object!,
      changingException: this.changingException
    })
  }

  setListViewState() {
    this.observableObjectViewState.setValue({
      type: "list"
    })
  }

  setRedirectViewState({
    url
  }: {
    readonly url: string
  }) {
    this.observableObjectViewState.setValue({
      type: "redirect",
      url
    })
  }

  async loadAndShowObject(): Promise<void> {
    this.setLoadingObjectViewState()

    const result: GetObjectResult<DomainObject> = await this.getObject!()

    switch (result.type) {
      case "error":
        this.setLoadingErrorObjectViewState({ error: result.error })
        break
      case "failure":
        this.setLoadingFailureObjectViewState({ exception: result.exception })
        break
      case "success":
        this.setObject(result.data)
        this.setLoadedObjectViewState()
        break
      default:
        assertNever(result)
    }
  }

  private setObject(object: DomainObject | undefined) {
    this.object = object
  }
}

export type GetObjectFunction<DomainObject> =
  () => Promise<GetObjectResult<DomainObject>>
