import React, { useMemo } from "react"
import RootComponent from "../../components/root/RootComponent"
import RootViewModel from "./RootViewModel"
import { Navigate, useLocation } from "react-router-dom"
import UrlProvider from "../../../../app/UrlProvider"
import useLocationContextProvider, { LocationContextProvider } from "../../../../lib/view-model/useLocationContextProvider"
import { useStateObservable } from "../../../../lib/view-model/StateObservable"
import assertNever from "../../../../lib/assertNever"
import LoadingIndicatorComponent from "../../../../lib/loading-indicator/LoadingIndicatorComponent"

interface Props {
  readonly provideViewModel: ({
    authToken
  }: {
    readonly authToken: string | null | undefined
  }) => RootViewModel
}

export default function RootPage({ provideViewModel }: Props) {
  const locationContextProvider: LocationContextProvider = useLocationContextProvider()
  const urlProvider = new UrlProvider()
  const location = useLocation()
  const parsedParameters = new URLSearchParams(location.search)
  const authToken = parsedParameters.get("auth_token")
  const viewModel = useMemo<RootViewModel>(() => provideViewModel({ authToken }), [])
  const rootViewState = useStateObservable(viewModel.rootStateObservable)

  switch (rootViewState.type) {
    case "initial":
      return <LoadingIndicatorComponent />
    case "authenticated":
      return (
        <RootComponent
          // TODO: onLogoutClicked={viewModel.onLogoutClicked}
          locationContextProvider={locationContextProvider}
          user={rootViewState.user}
        />
      )
    case "not_authenticated":
      return <Navigate to={urlProvider.buildForbiddenUrl()} replace={true} />
    default:
      assertNever(rootViewState)
  }
}
