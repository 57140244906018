import NetworkExecutionError from "../../../app/data/entities/network/errors/NetworkExecutionError"
import ObjectsPage from "../../../app/domain/entities/ObjectsPage"
import Subscription from "../../../app/domain/entities/Subscription"
import ErrorExecutionResult from "../../../app/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../app/domain/results/FailureExecutionResult"
import SuccessExecutionResult from "../../../app/domain/results/SuccessExecutionResult"
import SubscriptionsRepository from "../repositories/SubscriptionsRepository"

export default class GetSubscriptionsUseCase {
  private readonly subscriptionsRepository: SubscriptionsRepository

  constructor(parameters: {
    readonly subscriptionsRepository: SubscriptionsRepository
  }) {
    this.subscriptionsRepository = parameters.subscriptionsRepository
  }

  async call(): Promise<GetSubscriptionsResult> {
    return this.subscriptionsRepository.getSubscriptions()
  }
}

export type GetSubscriptionsResult =
  SuccessExecutionResult<ObjectsPage<Subscription>> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
