import autoBind from "auto-bind"
import assertNever from "../../../lib/assertNever"
import Page from "../../../app/domain/entities/Page"
import { ActionObservable } from "../../../lib/view-model/ActionObservable"
import { StateObservable } from "../../../lib/view-model/StateObservable"
import ExecutionError from "../../../app/domain/entities/ExecutionError"
import ApplicationException from "../../../app/domain/exceptions/ApplicationException"
import { AbstractObjectsViewState, ObjectsPageViewState } from "../view-states/ObjectsViewState"
import { ObjectsViewAction } from "../view-actions/ObjectsViewAction"
import ObjectsViewEvent from "../view-events/ObjectsViewEvent"
import ObjectsEvent, { ObjectsEventCallback } from "../../../objects/domain/entities/ObjectsEvent"
import { GetObjectsPageResult } from "../../../objects/domain/results/GetObjectsPageResult"
import SubscribeToObjectsEventsUseCase from "../../../objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase from "../../../objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import { TableColumnSortingType } from "../../../objects/presentation/entities/tables/TableColumnSortingType"
import Sort from "../../../objects/presentation/entities/tables/Sort"

const queryInputDebounceTimeoutInMilliseconds = 300

export default class ObjectsPresentationLogic<DomainObject> {
  private readonly getObjects: GetObjectsFunction<DomainObject>
  private readonly getObjectId: (object: DomainObject) => string
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private objectsEventsCallback?: ObjectsEventCallback
  private objects?: DomainObject[]
  private page?: Page
  private sort?: Sort
  private query?: string
  private needReloadObjectsOnReAttach: boolean
  private queryInputTimeout?: NodeJS.Timeout
  private isNextPageLoading = false
  private lastObjectsLoadingTimestamp?: number

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<DomainObject>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction> = new ActionObservable<ObjectsViewAction>()

  constructor(parameters: {
    readonly getObjects: GetObjectsFunction<DomainObject>
    readonly getObjectId: (object: DomainObject) => string
    readonly sort?: Sort,
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  }) {
    autoBind(this)
    this.getObjects = parameters.getObjects
    this.getObjectId = parameters.getObjectId
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.needReloadObjectsOnReAttach = false
    this.sort = parameters.sort
    this.observableObjectsPageViewState = this.createObservableObjectsPageViewState()
    this.subscribeToObjectsEvents()
    this.loadAndShowObjectsFirstPage().then()
  }

  destroy() {
    this.unsubscribeFromObjectsEvents()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    switch (objectsViewEvent.type) {
      case "resumed":
        // TODO: reload objects if related objects changed, not only current object type.
        if (this.getNeedReloadObjectsOnReAttach()) {
          this.setNeedReloadObjectsOnReAttach(false)
          this.loadAndShowObjectsFirstPage().then()
        }

        break
      case "query_changed":
        this.setQuery(objectsViewEvent.query)
        this.setIdleSearchViewState()

        clearTimeout(this.queryInputTimeout)
        this.queryInputTimeout = setTimeout(() => {
          this.loadAndShowObjectsFirstPage().then()
          this.cacheFilter()
        }, queryInputDebounceTimeoutInMilliseconds)

        break
      case "search_requested":
        this.loadAndShowObjectsFirstPage().then()
        break
      case "next_page_requested":
        if ((this.page?.hasMore ?? false) && !this.isNextPageLoading) {
          this.loadAndShowObjectsNextPage().then()
        }

        break
      case "sort_changing_requested":
        this.changeSort(objectsViewEvent.columnName)
        this.cacheSort()
        this.loadAndShowObjectsFirstPage().then()
        break
      case "retry_loading_first_page_requested":
        this.loadAndShowObjectsFirstPage().then()
        break
      case "retry_loading_next_page_requested":
        this.loadAndShowObjectsNextPage().then()
        break
      default:
        assertNever(objectsViewEvent)
    }
  }

  private createObservableObjectsPageViewState(): StateObservable<ObjectsPageViewState<DomainObject>> {
    return new StateObservable<ObjectsPageViewState<DomainObject>>({
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "initial"
      },
      searchViewState: { query: "" }
    })
  }

  private subscribeToObjectsEvents() {
    this.objectsEventsCallback = this.subscribeToObjectsEventsUseCase.call((event: ObjectsEvent) => {
      switch (event.type) {
        case "created":
        case "updated":
        case "destroyed":
          this.setNeedReloadObjectsOnReAttach(true)
          break
        default:
          assertNever(event)
      }
    })
  }

  private unsubscribeFromObjectsEvents() {
    this.objectsEventsCallback && this.unsubscribeFromObjectsEventsUseCase.call(this.objectsEventsCallback)
  }

  private async loadAndShowObjectsFirstPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.setObjects(undefined)
    this.setPage(undefined)
    this.setLoadingObjectsViewState()

    const result: GetObjectsPageResult<DomainObject> = await this.getObjects({
      query: this.query
      // sort: this.sort
    })

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setLoadingErrorObjectsViewState({ error: result.error })
        break
      case "failure":
        this.setLoadingFailureObjectsViewState({ exception: result.exception })
        break
      case "success":
        this.setObjects(result.data.objects)
        this.setPage(result.data.page)
        this.setLoadedObjectsViewState()
        break
    }
  }

  private async loadAndShowObjectsNextPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.isNextPageLoading = true
    this.setNextPageLoadingObjectsViewState()

    const lastObjectIndex: number = this.objects!.length - 1
    const lastObject: DomainObject | undefined = this.objects![lastObjectIndex]

    const result: GetObjectsPageResult<DomainObject> = await this.getObjects({
      query: this.query,
      // sort: this.sort,
      lastObject
    })

    this.isNextPageLoading = false

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setNextPageLoadingErrorObjectsViewState({ error: result.error })
        break
      case "failure":
        this.setNextPageLoadingFailureObjectsViewState({ exception: result.exception })
        break
      case "success":
        this.setObjects([...this.objects!, ...result.data.objects])
        this.setPage(result.data.page)
        this.setLoadedObjectsViewState()
        break
    }
  }

  private setIdleSearchViewState() {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      searchViewState: {
        query: this.query
      }
    })
  }

  private setLoadingObjectsViewState() {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "loading"
      }
    })
  }

  private setLoadingErrorObjectsViewState({ error }: { readonly error: ExecutionError }) {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "loading_error",
        error
      }
    })
  }

  private setLoadingFailureObjectsViewState({ exception }: { readonly exception: ApplicationException }) {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "loading_failure",
        exception
      }
    })
  }

  private setLoadedObjectsViewState() {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "loaded",
        objects: this.objects!,
        page: this.page!
      }
    })
  }

  private setNextPageLoadingObjectsViewState() {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "next_page_loading",
        objects: this.objects!
      }
    })
  }

  private setNextPageLoadingErrorObjectsViewState({ error }: { readonly error: ExecutionError }) {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "next_page_loading_error",
        objects: this.objects!,
        error
      }
    })
  }

  private setNextPageLoadingFailureObjectsViewState({ exception }: { readonly exception: ApplicationException }) {
    this.observableObjectsPageViewState.setValue({
      ...this.observableObjectsPageViewState.getValue(),
      objectsViewState: {
        ...this.getAbstractTableViewStateParameters(),
        type: "next_page_loading_failure",
        objects: this.objects!,
        exception
      }
    })
  }

  private getAbstractTableViewStateParameters(): AbstractObjectsViewState<DomainObject> {
    return {
      getObjectId: this.getObjectId,
      columns: [],
      sort: this.sort
    }
  }

  private setObjects(objects: DomainObject[] | undefined) {
    this.objects = objects
  }

  private setPage(page: Page | undefined) {
    this.page = page
  }

  private setQuery(query: string) {
    this.query = query
  }

  private cacheFilter() {
    this.observableObjectsViewAction.sendAction({ type: "cache_filter", filter: { query: this.query } })
  }

  private cacheSort() {
    this.observableObjectsViewAction.sendAction({ type: "cache_sort", sort: this.sort })
  }

  private getNeedReloadObjectsOnReAttach() {
    return this.needReloadObjectsOnReAttach
  }

  private setNeedReloadObjectsOnReAttach(needReloadObjectsOnReAttach: boolean) {
    this.needReloadObjectsOnReAttach = needReloadObjectsOnReAttach
  }

  private changeSort(columnName: string) {
    const currentSortType = this.sort?.id === columnName ? this.sort?.type : undefined
    const changedSortType = (() => {
      switch (currentSortType) {
        case TableColumnSortingType.ASC:
          return TableColumnSortingType.DESC
        case TableColumnSortingType.DESC:
          return undefined
        case undefined:
          return TableColumnSortingType.ASC
        default:
          return undefined
      }
    })()

    this.sort = changedSortType ? { id: columnName, type: changedSortType } : undefined
  }
}

export interface GetObjectsParameters<DomainObject> {
  readonly query?: string
  readonly lastObject?: DomainObject
}

export type GetObjectsFunction<DomainObject> =
  (parameters: GetObjectsParameters<DomainObject>) => Promise<GetObjectsPageResult<DomainObject>>
