import SubscriptionsMapper from "../../app/data/mappers/subscriptions/SubscriptionsMapper"
import SubscriptionsNetworkSource, { GetSubscriptionsNetworkResult } from "../../app/data/sources/network/SubscriptionsNetworkSource"
import Subscription from "../../app/domain/entities/Subscription"
import SubscriptionsRepository from "../domain/repositories/SubscriptionsRepository"
import { CancelSubscriptionParameters, CancelSubscriptionResult } from "../domain/use-cases/CancelSubscriptionUseCase"
import { GetSubscriptionParameters, GetSubscriptionResult } from "../domain/use-cases/GetSubscriptionUseCase"
import { GetSubscriptionsResult } from "../domain/use-cases/GetSubscriptionsUseCase"
import { PurchaseSubscriptionParameters, PurchaseSubscriptionResult } from "../domain/use-cases/PurchaseSubscriptionUseCase"

export default class DefaultSubscriptionsRepository implements SubscriptionsRepository {
  private readonly subscriptionsNetworkSource: SubscriptionsNetworkSource

  constructor(parameters: {
    readonly subscriptionsNetworkSource: SubscriptionsNetworkSource
  }) {
    this.subscriptionsNetworkSource = parameters.subscriptionsNetworkSource
  }

  async getSubscriptions(): Promise<GetSubscriptionsResult> {
    const result: GetSubscriptionsNetworkResult = await this.subscriptionsNetworkSource.getSubscriptions()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.subscriptions!.map((subscription: Subscription) => {
              return new SubscriptionsMapper().mapNetworkToDomain({ subscription })
            })
          }
        }
      default:
        return result
    }
  }

  async getSubscription(parameters: GetSubscriptionParameters): Promise<GetSubscriptionResult> {
    const result = await this.subscriptionsNetworkSource.getSubscription(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new SubscriptionsMapper().mapNetworkToDomain({
            subscription: result.data.subscription!
          })
        }
      default:
        return result
    }
  }

  async purchaseSubscription(
    parameters: PurchaseSubscriptionParameters
  ): Promise<PurchaseSubscriptionResult> {
    const result = await this.subscriptionsNetworkSource.purchaseSubscription(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new SubscriptionsMapper().mapNetworkToDomain({
            subscription: result.data
          })
        }
      default:
        return result
    }
  }

  async cancelSubscription(parameters: CancelSubscriptionParameters): Promise<CancelSubscriptionResult> {
    const result = await this.subscriptionsNetworkSource.cancelSubscription(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new SubscriptionsMapper().mapNetworkToDomain({
            subscription: result.data.subscription!
          })
        }
      default:
        return result
    }
  }
}
