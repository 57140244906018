import CoreTextProvider from "../CoreTextProvider"

export default class RuCoreTextProvider implements CoreTextProvider {
  appName(): string {
    return "Qbol"
  }

  appDescription(): string {
    return ""
  }

  profile(): string {
    return "Профиль"
  }

  logout(): string {
    return "Выйти"
  }

  confirmationQuestion(): string {
    return "Вы уверены?"
  }

  show(): string {
    return "Показать"
  }

  cancel(): string {
    return "Отмена"
  }

  empty(): string {
    return "Пусто"
  }

  contentLoading(): string {
    return "Загрузка..."
  }

  somethingWentWrong(): string {
    return "Что-то пошло не так"
  }

  yes(): string {
    return "Да"
  }

  no(): string {
    return "Нет"
  }

  back(): string {
    return "Назад"
  }

  setEmail(): string {
    return "Указать e-mail"
  }

  purchaseSubscription(): string {
    return "Оплатить подписку"
  }

  cancelSubscription(): string {
    return "Отменить подписку"
  }

  subscriptionsPageTitle(): string {
    return "Мои подписки"
  }

  account(): string {
    return "Аккаунт"
  }

  copyright(): string {
    return "© 2024 ООО «Qbol»"
  }

  subscriptionPrice({
    price
  }: { readonly price: string | null | undefined }): string {
    return `Сумма подписки в месяц ${price}`
  }

  cancelSubscriptionTitle(): string {
    return "Вы точно хотите отменить\nподписку?"
  }

  agreementText(): string {
    return "Я согласен с "
  }

  subscriptionOfferAgreementText(): string {
    return " условиями подписки"
  }

  privacyPolicyAgreementText(): string {
    return " политикой конфиденциальности"
  }

  subscriptionOfferUrl(): string {
    return "https://sequenia.com/qbol_subscription_agreement"
  }

  privacyPolicyUrl(): string {
    return "https://sequenia.com/qbol_ua#rec636129569"
  }

  and(): string {
    return " и"
  }

  subscriptionText({
    price
  }: {
    readonly price: string | null | undefined
  }): string {
    return `Я согласен на списание суммы в размере ${price} раз в месяц для оплаты подписки`
  }
}
