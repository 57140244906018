import React from "react"
import styles from "./TextButtonComponent.module.scss"
import typography from "../../ui_kit/typography.module.scss"

export default function TextButtonComponent({
  title = "",
  className,
  isDisabled = false,
  onClick = () => undefined
}: {
  readonly title?: string
  readonly className?: string
  readonly isDisabled?: boolean
  readonly onClick?: (event: React.MouseEvent) => void
}) {
  return (
    <button
      type="button"
      className={`${styles.root} ${typography.title1} ${className}`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  )
}
