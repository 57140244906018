import React from "react"
import DiModule from "../../../lib/di/DiModule"
import autoBind from "auto-bind"
import SubscriptionsViewModel from "../../presentation/pages/subscriptions-page/SubscriptionsViewModel"
import SubscriptionsDomainDiModule from "./SubscriptionsDomainDiModule"
import SubscriptionViewModel from "../../presentation/pages/subscription-page/SubscriptionViewModel"
import SubscriptionPage from "../../presentation/pages/subscription-page/SubscriptionPage"
import SubscriptionsPage from "../../presentation/pages/subscriptions-page/SubscriptionsPage"
import CoreI18nDiModule from "../../../app/di/modules/CoreI18nDiModule"
import ObjectsPresentationLogicParameters from "../../../objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import useObjectsPresentationLogicParameters from "../../../objects/presentation/hooks/useObjectsPresentationLogicParameters"
import ObjectsDomainDiModule from "../../../objects/di/modules/ObjectsDomainDiModule"

export default interface SubscriptionsPresentationDiModule {
  provideSubscriptionsPage(): React.ReactNode

  provideSubscriptionPage(): React.ReactNode
}

export class DefaultSubscriptionsPresentationDiModule
  extends DiModule
  implements SubscriptionsPresentationDiModule {

  private readonly subscriptionsDomainDiModule: SubscriptionsDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly objectsDomainDiModule: ObjectsDomainDiModule

  constructor(parameters: {
    readonly subscriptionsDomainDiModule: SubscriptionsDomainDiModule
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    super()
    autoBind(this)
    this.subscriptionsDomainDiModule = parameters.subscriptionsDomainDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
  }

  provideSubscriptionsPage(): React.ReactNode {
    return <SubscriptionsPage provideViewModel={this.provideSubscriptionsViewModel} />
  }

  provideSubscriptionPage(): React.ReactNode {
    return <SubscriptionPage provideViewModel={this.provideSubscriptionViewModel} />
  }

  private provideSubscriptionsViewModel(
    // TODO: any parameters
  ): SubscriptionsViewModel {
    const objectsPresentationLogicParameters: ObjectsPresentationLogicParameters =
      useObjectsPresentationLogicParameters()

    return new SubscriptionsViewModel({
      objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
        key: "subscriptions"
      }),
      unsubscribeFromObjectsEventsUseCase: this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
        key: "subscriptions"
      }),
      getSubscriptionsUseCase: this.subscriptionsDomainDiModule.provideGetSubscriptionsUseCase()
    })
  }

  private provideSubscriptionViewModel({
    subscriptionId
  }: {
    readonly subscriptionId?: number
  }): SubscriptionViewModel {
    return new SubscriptionViewModel({
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key: "subscriptions"
      }),
      getSubscriptionUseCase: this.subscriptionsDomainDiModule.provideGetSubscriptionUseCase(),
      purchaseSubscriptionUseCase: this.subscriptionsDomainDiModule.providePurchaseSubscriptionUseCase(),
      cancelSubscriptionUseCase: this.subscriptionsDomainDiModule.provideCancelSubscriptionUseCase(),
      updateCurrentUserUseCase: this.subscriptionsDomainDiModule.provideUpdateCurrentUserUseCase(),
      subscriptionId
    })
  }
}
