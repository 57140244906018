import HttpClient from "../../../lib/http-client/HttpClient"
import DiModule from "../../../lib/di/DiModule"
import BackendHttpClient from "../../data/network/BackendHttpClient"
import CoreLocalDiModule from "./CoreLocalDiModule"

export default interface CoreNetworkDiModule {
  provideHttpClient(): HttpClient

  provideBackendHttpClient(): BackendHttpClient
}

export class DefaultCoreNetworkDiModule extends DiModule implements CoreNetworkDiModule {
  private readonly coreLocalDiModule: CoreLocalDiModule
  private readonly baseUrl: string

  constructor(parameters: {
    readonly coreLocalDiModule: CoreLocalDiModule
    readonly baseUrl: string
  }) {
    super()
    this.coreLocalDiModule = parameters.coreLocalDiModule
    this.baseUrl = parameters.baseUrl
  }

  provideHttpClient(): HttpClient {
    return this.single(
      HttpClient.name,
      () => new HttpClient({
        baseUrl: this.baseUrl
      })
    )
  }

  provideBackendHttpClient(): BackendHttpClient {
    return this.single(
      BackendHttpClient.name,
      () => new BackendHttpClient({
        sessionLocalSource: this.coreLocalDiModule.provideSessionLocalSource(),
        httpClient: this.provideHttpClient()
      })
    )
  }
}
