import Sort from "../entities/tables/Sort"
import SortMapper from "../mappers/SortMapper"
import { useSearchParams } from "react-router-dom"
import ObjectsFilter from "../../domain/entities/ObjectsFilter"
import ObjectsFilterMapper from "../mappers/ObjectsFilterMapper"
import { plainToInstance } from "class-transformer"
import BrowserObjectsFilter from "../entities/object-filters/BrowserObjectsFilter"
import ObjectsPresentationLogicParameters from "../entities/objects/ObjectsPresentationLogicParameters"
import isBlank from "../../../lib/isBlank"

// TODO: private
export const SORT_KEY = "sort"
export const FILTER_KEY = "filter"

export default function useObjectsPresentationLogicParameters(): ObjectsPresentationLogicParameters {
  const [searchParams] = useSearchParams()

  function parseSort(jsonString: string | null): Sort | undefined {
    if (isBlank(jsonString)) {
      return undefined
    }

    return new SortMapper().mapBrowserSortToDomain({
      browserSort: jsonString
    })
  }

  function parseFilter(jsonString: string | null): ObjectsFilter | undefined {
    if (isBlank(jsonString)) {
      return undefined
    }

    return new ObjectsFilterMapper().mapBrowserObjectsFilterToDomain({
      browserObjectsFilter: plainToInstance(
        BrowserObjectsFilter,
        JSON.parse(jsonString) as object
      )
    })
  }

  return {
    sort: parseSort(searchParams.get(SORT_KEY)),
    filter: parseFilter(searchParams.get(FILTER_KEY))
  }
}
