import { Expose, Type } from "class-transformer"
import NetworkProfile from "../../profile/NetworkProfile"

export default class NetworkUser {
  @Expose({ name: "id" })
  @Type(() => Number)
  readonly id?: number | null

  @Expose({ name: "email" })
  @Type(() => String)
  readonly email?: string | null

  @Expose({ name: "profile" })
  @Type(() => NetworkProfile)
  readonly profile?: NetworkProfile | null
}
