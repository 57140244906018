import ProfileRepository from "../../domain/repositories/ProfileRepository"
import { GetProfileResult } from "../../domain/use-cases/profile/GetProfileUseCase"
import ProfileNetworkSource from "../sources/network/ProfileNetworkSource"

export default class DefaultProfileRepository implements ProfileRepository {
  private readonly profileNetworkSource: ProfileNetworkSource

  constructor(parameters: {
    readonly profileNetworkSource: ProfileNetworkSource
  }) {
    this.profileNetworkSource = parameters.profileNetworkSource
  }

  async getProfile(): Promise<GetProfileResult> {
    return await this.profileNetworkSource.getProfile()
  }
}
