import React from "react"
import SubscriptionsViewModel from "./SubscriptionsViewModel"
import Subscription from "../../../../app/domain/entities/Subscription"
import { useViewModel } from "../../../../lib/view-model/ViewModel"
import UrlProvider from "../../../../app/UrlProvider"
import useObjectsPresentationLogicParameters
  from "../../../../objects/presentation/hooks/useObjectsPresentationLogicParameters"
import ObjectsPresentationLogicParameters
  from "../../../../objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import SubscriptionsComponent from "./SubscriptionsComponent"
import CoreTextProvider from "../../../../app/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../app/presentation/contexts/CoreTextProviderContext"

export interface SubscriptionsPageParameters {
  readonly provideViewModel: (parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) => SubscriptionsViewModel
}

export default function SubscriptionsPage({
  provideViewModel
}: SubscriptionsPageParameters) {
  const urlProvider: UrlProvider = new UrlProvider()
  const objectsPresentationLogicParameters = useObjectsPresentationLogicParameters()
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  const viewModel: SubscriptionsViewModel = useViewModel(() => provideViewModel({
    objectsPresentationLogicParameters
  }))

  function getRecordUrl(subscription: Subscription): string {
    return urlProvider.buildSubscriptionUrl({ id: subscription.id!.toString() })
  }

  return (
    <SubscriptionsComponent
      textProvider={coreTextProvider}
      getRecordUrl={getRecordUrl}
      observableObjectsPageViewState={viewModel.observableObjectsPageViewState}
      onObjectsViewEvent={viewModel.onObjectsViewEvent}
    />
  )
}
