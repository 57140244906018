import { Expose, Type } from "class-transformer"

export default class BrowserObjectsFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  constructor(parameters?: {
    readonly query?: string | null
  }) {
    this.query = parameters?.query
  }
}
