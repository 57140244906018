import ExecutionError from "../../../app/domain/entities/ExecutionError"
import Subscription from "../../../app/domain/entities/Subscription"
import { UpdateObjectResult } from "../../../objects/domain/results/UpdateObjectResult"
import SubscriptionsRepository from "../repositories/SubscriptionsRepository"

export default class CancelSubscriptionUseCase {
  private readonly subscriptionsRepository: SubscriptionsRepository

  constructor(parameters: {
    readonly subscriptionsRepository: SubscriptionsRepository
  }) {
    this.subscriptionsRepository = parameters.subscriptionsRepository
  }

  async call(parameters: CancelSubscriptionParameters): Promise<CancelSubscriptionResult> {
    return await this.subscriptionsRepository.cancelSubscription(parameters)
  }
}

export interface CancelSubscriptionParameters {
  readonly id: number
}

export type CancelSubscriptionResult = UpdateObjectResult<Subscription, ExecutionError>
