import React, { useEffect } from "react"
import Subscription from "../../../../app/domain/entities/Subscription"
import { StateObservable, useStateObservable } from "../../../../lib/view-model/StateObservable"
import { ObjectsPageViewState } from "../../../../objects/presentation/view-states/ObjectsViewState"
import styles from "./SubscriptionsComponent.module.scss"
import typography from "../../../../ui_kit/typography.module.scss"
import ErrorMessageComponent from "../../../../lib/error-message/ErrorMessageComponent"
import ContentLoadingComponent from "../../../../app/presentation/components/content-loading/ContentLoadingComponent"
import CoreTextProvider from "../../../../app/i18n/CoreTextProvider"
import SubscriptionItemComponent from "./SubscriptionItemComponent/SubscriptionItemComponent"
import { TextStyle } from "../../../../lib/text/TextComponent"
import ObjectsViewEvent from "../../../../objects/presentation/view-events/ObjectsViewEvent"

export interface SubscriptionsComponentProps {
  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<Subscription>>
  readonly textProvider: CoreTextProvider
  readonly getRecordUrl: (subscription: Subscription) => string
  readonly onObjectsViewEvent: (objectsViewEvent: ObjectsViewEvent) => void
}

export default function SubscriptionsComponent({
  observableObjectsPageViewState,
  textProvider,
  getRecordUrl,
  onObjectsViewEvent
}: SubscriptionsComponentProps) {
  const objectsPageViewState: ObjectsPageViewState<Subscription> =
    useStateObservable(observableObjectsPageViewState)

  const { objectsViewState } = objectsPageViewState

  function handleResumed() {
    onObjectsViewEvent({ type: "resumed" })
  }

  useEffect(handleResumed, [])

  return (
    <>
      {(() => {
        switch (objectsViewState.type) {
          case "initial":
            return <></>
          case "loading":
            return (
              <div className={styles.loaderWrapper}>
                <ContentLoadingComponent />
              </div>
            )
          case "loading_error":
            return (
              <ErrorMessageComponent
                text={objectsViewState.error.message}
                messageTextStyle={TextStyle.LABEL1_PRIMARY}
                buttonTextStyle={TextStyle.HEADER1_PRIMARY}
              />
            )
          case "loading_failure":
            return (
              <ErrorMessageComponent
                text={objectsViewState.exception.message}
                messageTextStyle={TextStyle.LABEL1_PRIMARY}
                buttonTextStyle={TextStyle.HEADER1_PRIMARY}
              />
            )
          case "loaded":
            return (
              <div className={styles.root}>
                <h1 className={`${styles.title} ${typography.header5}`}>
                  {textProvider.subscriptionsPageTitle()}
                </h1>

                {objectsViewState.objects.map((subscription: Subscription) => {
                  const objectUrl: string = getRecordUrl(subscription)

                  return (
                    <SubscriptionItemComponent
                      subscription={subscription}
                      url={objectUrl}
                      key={subscription.id}
                    />
                  )
                })}
              </div>
            )
        }
      })()}

    </>
  )
}
