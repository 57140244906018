import ObjectsDataDiModule from "./ObjectsDataDiModule"
import SubscribeToObjectsEventsUseCase from "../../domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase from "../../domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import BroadcastObjectsEventUseCase from "../../domain/use-cases/objects/BroadcastObjectsEventUseCase"
import DiModule from "../../../lib/di/DiModule"

export default interface ObjectsDomainDiModule {
  provideBroadcastObjectsEventUseCase(parameters: { readonly key: string }): BroadcastObjectsEventUseCase

  provideSubscribeToObjectsEventsUseCase(parameters: { readonly key: string }): SubscribeToObjectsEventsUseCase

  provideUnsubscribeFromObjectsEventsUseCase(parameters: { readonly key: string }): UnsubscribeFromObjectsEventsUseCase
}

export class DefaultObjectsDomainDiModule
  extends DiModule
  implements ObjectsDomainDiModule {

  private readonly objectsDataDiModule: ObjectsDataDiModule

  constructor(parameters: {
    readonly objectsDataDiModule: ObjectsDataDiModule
  }) {
    super()
    this.objectsDataDiModule = parameters.objectsDataDiModule
  }

  provideBroadcastObjectsEventUseCase({ key }: { readonly key: string }): BroadcastObjectsEventUseCase {
    return this.single(
      `${BroadcastObjectsEventUseCase}_${key}`,
      () => new BroadcastObjectsEventUseCase({
        objectsRepository: this.objectsDataDiModule.provideObjectsRepository({
          key
        })
      })
    )
  }

  provideSubscribeToObjectsEventsUseCase({ key }: { readonly key: string }): SubscribeToObjectsEventsUseCase {
    return this.single(
      `${SubscribeToObjectsEventsUseCase.name}_${key}`,
      () => new SubscribeToObjectsEventsUseCase({
        objectsRepository: this.objectsDataDiModule.provideObjectsRepository({
          key
        })
      })
    )
  }

  provideUnsubscribeFromObjectsEventsUseCase({ key }: { readonly key: string }): UnsubscribeFromObjectsEventsUseCase {
    return this.single(
      `${UnsubscribeFromObjectsEventsUseCase.name}_${key}`,
      () => new UnsubscribeFromObjectsEventsUseCase({
        objectsRepository: this.objectsDataDiModule.provideObjectsRepository({
          key
        })
      })
    )
  }
}
