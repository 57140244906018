import { Expose, Type } from "class-transformer"

export default class NetworkSubscriptionPermissionsSet {
  @Expose({ name: "needs_email" })
  @Type(() => Boolean)
  readonly needsEmail?: boolean | null

  @Expose({ name: "can_purchase" })
  @Type(() => Boolean)
  readonly canPurchase?: boolean | null

  @Expose({ name: "can_suspend" })
  @Type(() => Boolean)
  readonly canSuspend?: boolean | null
}
