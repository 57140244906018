import React from "react"
import styles from "./ButtonComponent.module.scss"
import typography from "../../ui_kit/typography.module.scss"

export default function ButtonComponent({
  title = "",
  className,
  isDisabled = false,
  onClick = () => undefined
}: {
  readonly title?: string
  readonly className?: string
  readonly isDisabled?: boolean
  readonly onClick?: (event: React.MouseEvent) => void
}) {
  return (
    <button
      type="submit"
      className={`${styles.root} ${typography.header2} ${className}`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </button>
  )
}
