import React from "react"
import Subscription from "../../../../../app/domain/entities/Subscription"
import { Link } from "react-router-dom"
import styles from "./SubscriptionItemComponent.module.scss"
import typography from "../../../../../ui_kit/typography.module.scss"
import { ReactComponent as IcArrowRight } from "../../../../../resources/icons/icArrowRight.svg"

export interface SubscriptionItemComponentProps {
  readonly subscription: Subscription
  readonly url: string
}

export default function SubscriptionItemComponent({
  subscription,
  url
}: SubscriptionItemComponentProps) {
  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        {subscription.chat?.chatCoverUrl && (
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${subscription.chat!.chatCoverUrl!})` }}
          />
        )}
        <div className={`${styles.title} ${typography.title4}`}>
          {subscription.chat?.name}
        </div>
      </div>
      <div className={styles.separator} />
      <Link to={url} className={styles.link}>
        <div className={`${styles.linkText} ${typography.body2}`}>
          {subscription.statusExplanation}
        </div>
        <IcArrowRight
          className={styles.arrow}
        />
      </Link>
    </div>
  )
}
