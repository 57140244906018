import GetProfileUseCase from "../../domain/use-cases/profile/GetProfileUseCase"
import DiModule from "../../../lib/di/DiModule"
import ProfileDataDiModule from "./ProfileDataDiModule"

export default interface ProfileDomainDiModule {
  provideGetProfileUseCase(): GetProfileUseCase
}

export class DefaultProfileDomainDiModule extends DiModule implements ProfileDomainDiModule {
  private readonly profileDataDiModule: ProfileDataDiModule

  constructor(parameters: {
    profileDataDiModule: ProfileDataDiModule
  }) {
    super()
    this.profileDataDiModule = parameters.profileDataDiModule
  }

  provideGetProfileUseCase(): GetProfileUseCase {
    return this.single(
      GetProfileUseCase.name,
      () => new GetProfileUseCase({
        profileRepository: this.profileDataDiModule.provideProfileRepository()
      })
    )
  }
}
