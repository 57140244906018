import UsersMapper from "../mappers/users/UsersMapper"
import CurrentUserNetworkSource from "../sources/network/CurrentUserNetworkSource"
import CurrentUserRepository from "../../domain/repositories/CurrentUserRepository"
import { UpdateCurrentUserParameters, UpdateCurrentUserResult } from "../../domain/use-cases/current-user/UpdateCurrentUserUseCase"
import { GetCurrentUserResult } from "../../domain/use-cases/current-user/GetCurrentUserUseCase"

export default class DefaultCurrentUserRepository implements CurrentUserRepository {
  private readonly currentUserNetworkSource: CurrentUserNetworkSource

  constructor(parameters: {
    readonly currentUserNetworkSource: CurrentUserNetworkSource
  }) {
    this.currentUserNetworkSource = parameters.currentUserNetworkSource
  }

  async getCurrentUser(): Promise<GetCurrentUserResult> {
    const result = await this.currentUserNetworkSource.getCurrentUser()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async updateCurrentUser(
    parameters: UpdateCurrentUserParameters
  ): Promise<UpdateCurrentUserResult> {
    const result = await this.currentUserNetworkSource.updateCurrentUser(parameters)

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }
}
