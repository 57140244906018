import DiModule from "../../../lib/di/DiModule"
import CoreNetworkDiModule from "./CoreNetworkDiModule"
import SubscriptionsNetworkSource from "../../data/sources/network/SubscriptionsNetworkSource"
import DefaultSubscriptionsNetworkSource from "../../data/sources/network/DefaultSubscriptionsNetworkSource"

export default interface CoreSubscriptionsNetworkDiModule {
  provideSubscriptionsNetworkSource(): SubscriptionsNetworkSource
}

export class DefaultCoreSubscriptionsNetworkDiModule
  extends DiModule
  implements CoreSubscriptionsNetworkDiModule {

  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  provideSubscriptionsNetworkSource(): SubscriptionsNetworkSource {
    return this.single(
      DefaultSubscriptionsNetworkSource.name,
      () => new DefaultSubscriptionsNetworkSource({
        backendHttpClient: this.coreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
