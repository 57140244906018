import React from "react"
import styles from "./BackComponent.module.scss"
import typography from "../../ui_kit/typography.module.scss"
import { ReactComponent as IcArrowLeft } from "../../resources/icons/icArrowLeft.svg"
import { useCoreTextProvider } from "../../app/presentation/contexts/CoreTextProviderContext"

export interface BackComponentProps {
  readonly onBackButtonClicked: () => void
}

export default function BackComponent({
  onBackButtonClicked
}: BackComponentProps) {
  const textProvider = useCoreTextProvider()

  if (window.history.length <= 1) return <></>

  return (
    <div className={styles.root}>
      <div
        className={styles.buttonContainer}
        onClick={onBackButtonClicked}
      >
        <IcArrowLeft
          className={styles.arrow}
        />
        <div className={`${styles.text} ${typography.header4}`}>
          {textProvider.back()}
        </div>
      </div>
    </div>
  )
}
