import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import CurrentUserNetworkSource, {
  GetCurrentUserNetworkResult,
  UpdateCurrentUserNetworkParameters,
  UpdateCurrentUserNetworkResult
} from "./CurrentUserNetworkSource"
import NetworkUser from "../../entities/network/users/NetworkUser"
import NetworkExecutionError from "../../entities/network/errors/NetworkExecutionError"
import NetworkUpdateCurrentUserResponseBody
  from "../../entities/network/users/response-bodies/NetworkUpdateCurrentUserResponseBody"
import NetworkCurrentUserResponseBody from "../../entities/network/users/response-bodies/NetworkCurrentUserResponseBody"

const basePath = "/api/v1/client/current_user"

export default class DefaultCurrentUserNetworkSource implements CurrentUserNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getCurrentUser(): Promise<GetCurrentUserNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.GET,
        path: `${basePath}`
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkUser = plainToInstance(
          NetworkCurrentUserResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkUser
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }

  async updateCurrentUser(
    parameters: UpdateCurrentUserNetworkParameters
  ): Promise<UpdateCurrentUserNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult =
      await this.backendHttpClient.executeRequest({
        type: HttpRequestType.PUT,
        path: `${basePath}`,
        body: parameters
      })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkCurrentUser: NetworkUser = plainToInstance(
          NetworkUpdateCurrentUserResponseBody,
          backendHttpClientResult.body
        ).user!

        return {
          type: "success",
          data: {
            user: networkCurrentUser
          }
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, backendHttpClientResult.body)
        }
      case "failure":
        return backendHttpClientResult
    }
  }
}
