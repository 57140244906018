import React from "react"
import styles from "./PageFooterComponent.module.scss"
import typography from "../../../../../ui_kit/typography.module.scss"
import { useCoreTextProvider } from "../../../../../app/presentation/contexts/CoreTextProviderContext"

export default function PageFooterComponent() {
  const textProvider = useCoreTextProvider()

  return (
    <div className={styles.root}>
      <div className={`${styles.copyright} ${typography.body2}`}>
        {textProvider.copyright()}
      </div>
    </div>
  )
}
