import isBlank from "../../../lib/isBlank"
import ObjectsFilter from "../../domain/entities/ObjectsFilter"
import BrowserObjectsFilter from "../entities/object-filters/BrowserObjectsFilter"

export default class ObjectsFilterMapper {
  mapDomainObjectsFilterToBrowser({
    objectsFilter
  }: {
    readonly objectsFilter?: ObjectsFilter
  }): BrowserObjectsFilter | undefined {
    if (!objectsFilter || isBlank(objectsFilter.query)) return undefined

    return new BrowserObjectsFilter({
      query: objectsFilter?.query
    })
  }

  mapBrowserObjectsFilterToDomain({
    browserObjectsFilter
  }: {
    readonly browserObjectsFilter: BrowserObjectsFilter
  }): ObjectsFilter {
    return {
      query: browserObjectsFilter.query ?? undefined
    }
  }
}
