import { StateObservable } from "../../../../lib/view-model/StateObservable"
import autoBind from "auto-bind"
import ViewModel from "../../../../lib/view-model/ViewModel"
import { RootViewState } from "./RootViewState"
import DestroySessionUseCase from "../../../../app/domain/use-cases/sessions/DestroySessionUseCase"
import IsSessionExistUseCase from "../../../../app/domain/use-cases/sessions/IsSessionExistUseCase"
import CreateSessionUseCase from "../../../../app/domain/use-cases/sessions/CreateSessionUseCase"
import isPresent from "../../../../lib/isPresent"
import User from "../../../../app/domain/entities/User"
import GetCurrentUserUseCase from "../../../../app/domain/use-cases/current-user/GetCurrentUserUseCase"

export default class RootViewModel extends ViewModel {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly createSessionUseCase: CreateSessionUseCase
  private readonly destroySessionUseCase: DestroySessionUseCase
  private readonly getCurrentUserUseCase: GetCurrentUserUseCase
  private readonly authToken: string | null | undefined
  private user: User | undefined | null

  rootStateObservable = new StateObservable<RootViewState>({ type: "initial" })

  constructor(parameters: {
    readonly authToken: string | null | undefined
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly createSessionUseCase: CreateSessionUseCase
    readonly destroySessionUseCase: DestroySessionUseCase
    readonly getCurrentUserUseCase: GetCurrentUserUseCase
  }) {
    super()

    autoBind(this)

    this.getCurrentUserUseCase = parameters.getCurrentUserUseCase
    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.createSessionUseCase = parameters.createSessionUseCase
    this.destroySessionUseCase = parameters.destroySessionUseCase
    this.authToken = parameters.authToken

    this.checkIsSessionExistAndSetRootViewState()
  }

  onLogoutClicked() {
    this.destroySessionAndSetNotAuthenticatedRootViewState()
  }

  private checkIsSessionExistAndSetRootViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()
    if (isPresent(this.authToken)) {
      this.createSessionByTokenAndSetRootViewState({ authToken: this.authToken }).then()
    } else {
      if (isSessionExist) {
        this.getCurrentUserAndSetRootViewState().then()
      } else {
        this.setNotAuthenticatedRootViewState()
      }
    }
  }

  private destroySessionAndSetNotAuthenticatedRootViewState() {
    this.destroySessionUseCase.call()
    this.setNotAuthenticatedRootViewState()
  }

  private async createSessionByTokenAndSetRootViewState({
    authToken
  }: {
    readonly authToken: string
  }) {
    const createSessionResult = await this.createSessionUseCase.call({ authToken })

    switch (createSessionResult.type) {
      case "success":
        break
      case "error":
        return this.setNotAuthenticatedRootViewState()
      case "failure":
        return this.setNotAuthenticatedRootViewState()
    }

    await this.getCurrentUserAndSetRootViewState()
  }

  private async getCurrentUserAndSetRootViewState() {
    const getCurrentUserResult = await this.getCurrentUserUseCase.call()

    switch (getCurrentUserResult.type) {
      case "success":
        this.user = getCurrentUserResult.data
        this.setAuthenticatedRootViewState()
        break
      case "error":
        this.setNotAuthenticatedRootViewState()
        break
      case "failure":
        this.setNotAuthenticatedRootViewState()
        break
    }
  }

  private setAuthenticatedRootViewState() {
    this.rootStateObservable.setValue({ type: "authenticated", user: this.user })
  }

  private setNotAuthenticatedRootViewState() {
    this.rootStateObservable.setValue({ type: "not_authenticated" })
  }
}
