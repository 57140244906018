import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { plainToInstance } from "class-transformer"
import SessionsNetworkSource, { CreateSessionNetworkResult } from "./SessionsNetworkSource"
import NetworkUserSession from "../../entities/network/sessions/NetworkUserSession"
import NetworkCreateSessionError from "../../entities/network/sessions/NetworkCreateSessionError"

export default class DefaultSessionNetworkSource implements SessionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async createSession({ authToken }: { readonly authToken?: string | null }): Promise<CreateSessionNetworkResult> {
    const backendHttpClientResult: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: "/api/v1/client/sessions/create_by_token",
      body: { auth_token: authToken }
    })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkUserSession: NetworkUserSession = plainToInstance(
          NetworkUserSession,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkUserSession
        }
      }
      case "error": {
        const error: NetworkCreateSessionError = plainToInstance(
          NetworkCreateSessionError,
          backendHttpClientResult.body
        )

        return {
          type: "error",
          error
        }
      }
      case "failure":
        return backendHttpClientResult
    }
  }
}
