import assertNever from "../assertNever"
import isPresent from "../isPresent"

export default class HttpClient {
  private readonly baseUrl: string

  constructor(parameters: {
    readonly baseUrl: string
  }) {
    this.baseUrl = parameters.baseUrl
  }

  async executeRequest({
    type,
    path,
    parameters,
    body,
    headers
  }: {
    readonly type: HttpRequestType
    readonly path: string
    readonly parameters?: unknown
    readonly body?: string | FormData
    readonly headers?: Headers
  }): Promise<HttpClientResult> {
    const url: URL = this.buildUrl({ path, parameters })
    const method: string = this.getFetchMethodForHttpRequestType(type)

    try {
      const response: Response = await fetch(url, { method, body, headers })
      const responseBody: string = await response.text()
      const status: number = response.status

      if (!response.ok) {
        return { type: "http_client_error", body: responseBody, status }
      }

      return { type: "http_client_success", body: responseBody, status }
    } catch (exception) {
      return { type: "http_client_failure", exception }
    }
  }

  private buildUrl({
    path,
    parameters
  }: {
    readonly path: string
    readonly parameters?: unknown
  }): URL {
    const url: URL = new URL(path, this.baseUrl)

    if (isPresent(parameters)) {
      url.search = this.convertObjectToUrlParameters(parameters)
    }

    return url
  }

  private getFetchMethodForHttpRequestType(httpRequestType: HttpRequestType): string {
    switch (httpRequestType) {
      case HttpRequestType.GET:
        return "GET"
      case HttpRequestType.POST:
        return "POST"
      case HttpRequestType.PUT:
        return "PUT"
      case HttpRequestType.DELETE:
        return "DELETE"
      default:
        assertNever(httpRequestType)
    }
  }

  private convertObjectToUrlParameters(object: unknown): string {
    return this.mapObjectToQueryParts(object, 0)
      .map((queryPart: QueryPart) => {
        return `${encodeURIComponent(queryPart.key)}=${encodeURIComponent(queryPart.value)}`
      })
      .join("&")
  }

  private mapObjectToQueryParts(object: unknown, depthIndex: number): QueryPart[] {
    if (object === null || object === undefined) {
      return []
    }

    const queryParts: QueryPart[] = []

    if (Array.isArray(object) || typeof object === "object") {
      Object.entries(object).forEach(([key, value]) => {
        const objectKey: string = Array.isArray(object) ? "" : key
        const queryPartKey: string = depthIndex === 0 ? objectKey : `[${objectKey}]`

        if (Array.isArray(value) || typeof value === "object") {
          const nestedQueryParts: QueryPart[] = this.mapObjectToQueryParts(value, depthIndex + 1)

          nestedQueryParts.forEach((nestedQueryPart: QueryPart) => {
            queryParts.push({
              key: `${queryPartKey}${nestedQueryPart.key}`,
              value: nestedQueryPart.value
            })
          })
        } else if (value !== null && value !== undefined) {
          queryParts.push({
            key: queryPartKey,
            value: value.toString()
          })
        }
      })
    }

    return queryParts
  }
}

interface QueryPart {
  readonly key: string
  readonly value: string
}

export type Headers = {
  [key: string]: string
}

export enum HttpRequestType {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete"
}

export type HttpClientSuccessResult = {
  readonly type: "http_client_success"
  readonly body: string
  readonly status: number
}

export type HttpClientErrorResult = {
  readonly type: "http_client_error"
  readonly body: string
  readonly status: number
}

export type HttpClientFailureResult = {
  readonly type: "http_client_failure"
  readonly exception: unknown
}

export type HttpClientResult =
  HttpClientSuccessResult |
  HttpClientErrorResult |
  HttpClientFailureResult
