import DiModule from "../../../lib/di/DiModule"
import CancelSubscriptionUseCase from "../../domain/use-cases/CancelSubscriptionUseCase"
import GetSubscriptionUseCase from "../../domain/use-cases/GetSubscriptionUseCase"
import GetSubscriptionsUseCase from "../../domain/use-cases/GetSubscriptionsUseCase"
import PurchaseSubscriptionUseCase from "../../domain/use-cases/PurchaseSubscriptionUseCase"
import UpdateCurrentUserUseCase from "../../../app/domain/use-cases/current-user/UpdateCurrentUserUseCase"
import SubscriptionsDataDiModule from "./SubscriptionsDataDiModule"

export default interface SubscriptionsDomainDiModule {
  provideGetSubscriptionsUseCase(): GetSubscriptionsUseCase

  provideGetSubscriptionUseCase(): GetSubscriptionUseCase

  providePurchaseSubscriptionUseCase(): PurchaseSubscriptionUseCase

  provideCancelSubscriptionUseCase(): CancelSubscriptionUseCase

  provideUpdateCurrentUserUseCase(): UpdateCurrentUserUseCase
}

export class DefaultSubscriptionsDomainDiModule
  extends DiModule
  implements SubscriptionsDomainDiModule {

  private readonly subscriptionsDataDiModule: SubscriptionsDataDiModule

  constructor(parameters: {
    readonly subscriptionsDataDiModule: SubscriptionsDataDiModule
  }) {
    super()
    this.subscriptionsDataDiModule = parameters.subscriptionsDataDiModule
  }

  provideGetSubscriptionsUseCase(): GetSubscriptionsUseCase {
    return this.single(
      GetSubscriptionsUseCase.name,
      () => new GetSubscriptionsUseCase({
        subscriptionsRepository: this.subscriptionsDataDiModule.provideSubscriptionsRepository()
      })
    )
  }

  provideGetSubscriptionUseCase(): GetSubscriptionUseCase {
    return this.single(
      GetSubscriptionUseCase.name,
      () => new GetSubscriptionUseCase({
        subscriptionsRepository: this.subscriptionsDataDiModule.provideSubscriptionsRepository()
      })
    )
  }

  providePurchaseSubscriptionUseCase(): PurchaseSubscriptionUseCase {
    return this.single(
      PurchaseSubscriptionUseCase.name,
      () => new PurchaseSubscriptionUseCase({
        subscriptionsRepository: this.subscriptionsDataDiModule.provideSubscriptionsRepository()
      })
    )
  }

  provideCancelSubscriptionUseCase(): CancelSubscriptionUseCase {
    return this.single(
      CancelSubscriptionUseCase.name,
      () => new CancelSubscriptionUseCase({
        subscriptionsRepository: this.subscriptionsDataDiModule.provideSubscriptionsRepository()
      })
    )
  }

  provideUpdateCurrentUserUseCase(): UpdateCurrentUserUseCase {
    return this.single(
      UpdateCurrentUserUseCase.name,
      () => new UpdateCurrentUserUseCase({
        currentUserRepository: this.subscriptionsDataDiModule.provideCurrentUserRepository()
      })
    )
  }
}
