import Subscription from "../../../app/domain/entities/Subscription"
import { GetObjectResult } from "../../../objects/domain/results/GetObjectResult"
import SubscriptionsRepository from "../repositories/SubscriptionsRepository"

export default class GetSubscriptionUseCase {
  private readonly subscriptionsRepository: SubscriptionsRepository

  constructor(parameters: {
    readonly subscriptionsRepository: SubscriptionsRepository
  }) {
    this.subscriptionsRepository = parameters.subscriptionsRepository
  }

  async call(parameters: GetSubscriptionParameters): Promise<GetSubscriptionResult> {
    return await this.subscriptionsRepository.getSubscription(parameters)
  }
}

export interface GetSubscriptionParameters {
  readonly id: number
}

export type GetSubscriptionResult = GetObjectResult<Subscription>
