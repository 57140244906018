import CoreI18nDiModule from "../modules/CoreI18nDiModule"
import CoreI18n from "../../i18n/CoreI18n"

export default class CoreDiComponent {
  private readonly coreI18nDiModule: CoreI18nDiModule

  constructor(parameters: {
    readonly coreI18nDiModule: CoreI18nDiModule
  }) {
    this.coreI18nDiModule = parameters.coreI18nDiModule
  }

  provideCoreI18n(): CoreI18n {
    return this.coreI18nDiModule.provideCoreI18n()
  }
}
