import { Expose, Type } from "class-transformer"
import NetworkCreateSessionErrorsObject from "./NetworkCreateSessionErrorsObject"
import {
  NetworkCreateSessionError as DomainNetworkCreateSessionError
} from "../../../sources/network/SessionsNetworkSource"

export default class NetworkCreateSessionError implements DomainNetworkCreateSessionError {
  @Expose()
  readonly message?: string | null

  @Expose()
  @Type(() => NetworkCreateSessionErrorsObject)
  readonly errorsObject?: NetworkCreateSessionErrorsObject | null
}
