import React from "react"
import SubscriptionViewModel from "./SubscriptionViewModel"
import SubscriptionComponent from "./SubscriptionComponent"
import { useViewModel } from "../../../../lib/view-model/ViewModel"
import { useNumberObjectId } from "../../../../app/presentation/hooks/useObjectId"

export interface SubscriptionPageProps {
  readonly provideViewModel: (parameters: { readonly subscriptionId?: number }) => SubscriptionViewModel
}

export default function SubscriptionPage({
  provideViewModel
}: SubscriptionPageProps) {
  const subscriptionId: number | undefined = useNumberObjectId()

  const viewModel: SubscriptionViewModel = useViewModel(() => provideViewModel({
    subscriptionId
  }))

  return (
    <SubscriptionComponent
      observableObjectViewState={viewModel.observableObjectViewState}
      onCancelSubscriptionClicked={viewModel.onCancelSubscriptionHandler}
      onPurchaseSubscriptionClicked={viewModel.onPurchaseSubscriptionHandler}
      onSaveEmailClicked={viewModel.onSaveEmailHandler}
      onEmailChangedHandler={viewModel.onEmailChangedHandler}
      onBackButtonClicked={viewModel.onBackButtonClickedHandler}
    />
  )
}
