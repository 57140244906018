import autoBind from "auto-bind"
import Subscription from "../../../../app/domain/entities/Subscription"
import { ActionObservable } from "../../../../lib/view-model/ActionObservable"
import { StateObservable } from "../../../../lib/view-model/StateObservable"
import ViewModel from "../../../../lib/view-model/ViewModel"
import GetSubscriptionsUseCase from "../../../domain/use-cases/GetSubscriptionsUseCase"
import ObjectsPresentationLogic from "../../../../objects/presentation/presentation-logics/ObjectsPresentationLogic"
import { ObjectsPageViewState } from "../../../../objects/presentation/view-states/ObjectsViewState"
import SubscribeToObjectsEventsUseCase from "../../../../objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase from "../../../../objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import { ObjectsViewAction } from "../../../../objects/presentation/view-actions/ObjectsViewAction"
import ObjectsViewEvent from "../../../../objects/presentation/view-events/ObjectsViewEvent"
import ObjectsPresentationLogicParameters from "../../../../objects/presentation/entities/objects/ObjectsPresentationLogicParameters"

export default class SubscriptionsViewModel extends ViewModel {
  private readonly getSubscriptionsUseCase: GetSubscriptionsUseCase
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<Subscription>
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<Subscription>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getSubscriptionsUseCase: GetSubscriptionsUseCase
  }) {
    super()
    autoBind(this)

    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getSubscriptionsUseCase = parameters.getSubscriptionsUseCase

    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<Subscription> {
    return new ObjectsPresentationLogic<Subscription>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async() => {
        return await this.getSubscriptionsUseCase.call()
      },
      getObjectId: (subscription: Subscription) => subscription.id!.toString()
    })
  }
}
