import { Expose, Type } from "class-transformer"
import DomainNetworkSession from "../../../../domain/entities/network/sessions/NetworkSession"

export default class NetworkSession implements DomainNetworkSession {
  @Expose({ name: "access_token" })
  @Type(() => String)
  accessToken?: string | null

  @Expose({ name: "refresh_token" })
  @Type(() => String)
  refreshToken?: string | null
}
