import React from "react"
import CoreDiComponent from "./di/components/CoreDiComponent"
import RootDiComponent from "../root/di/components/RootDiComponent"
import CoreNetworkDiModule, { DefaultCoreNetworkDiModule } from "./di/modules/CoreNetworkDiModule"
import { DefaultRootPresentationDiModule } from "../root/di/modules/RootPresentationDiModule"
import { DefaultCoreI18nModule } from "./di/modules/CoreI18nDiModule"
import { DefaultCoreDataDiModule } from "./di/modules/CoreDataDiModule"
import { DefaultCoreDomainDiModule } from "./di/modules/CoreDomainDiModule"
import { DefaultCoreLocalDiModule } from "./di/modules/CoreLocalDiModule"
import { DefaultCoreSessionsNetworkDiModule } from "./di/modules/CoreSessionsNetworkDiModule"
import { DefaultCoreSubscriptionsNetworkDiModule } from "./di/modules/CoreSubscriptionsNetworkDiModule"
import SubscriptionsDiComponent from "../subscriptions/di/components/SubscriptionsDiComponent"
import { DefaultSubscriptionsPresentationDiModule } from "../subscriptions/di/modules/SubscriptionsPresentationDiModule"
import { DefaultSubscriptionsDomainDiModule } from "../subscriptions/di/modules/SubscriptionsDomainDiModule"
import { DefaultSubscriptionsDataDiModule } from "../subscriptions/di/modules/SubscriptionsDataDiModule"
import { DefaultObjectsDomainDiModule } from "../objects/di/modules/ObjectsDomainDiModule"
import { DefaultObjectsDataDiModule } from "../objects/di/modules/ObjectsDataDiModule"
import { DefaultCoreCurrentUserNetworkDiModule } from "./di/modules/CoreCurrentUserNetworkDiModule"
import { DefaultCurrentUserDataDiModule } from "./di/modules/CurrentUserDataDiModule"
import { DefaultCurrentUserDomainDiModule } from "./di/modules/CurrentUserDomainDiModule"
import { DefaultProfileNetworkDiModule } from "./di/modules/network/ProfileNetworkDiModule"
import { DefaultProfileDataDiModule } from "./di/modules/ProfileDataDiModule"
import { DefaultProfileDomainDiModule } from "./di/modules/ProfileDomainDiModule"

const localStorageKeyPrefix = "QBOL_WEB_"

export class Di {
  readonly coreDiComponent: CoreDiComponent
  readonly rootDiComponent: RootDiComponent
  readonly subscriptionsDiComponent: SubscriptionsDiComponent

  constructor(parameters: {
    readonly coreDiComponent: CoreDiComponent
    readonly rootDiComponent: RootDiComponent
    readonly subscriptionsDiComponent: SubscriptionsDiComponent
  }) {
    this.coreDiComponent = parameters.coreDiComponent
    this.rootDiComponent = parameters.rootDiComponent
    this.subscriptionsDiComponent = parameters.subscriptionsDiComponent
  }

  provideRootPage(): React.ReactNode {
    return this
      .rootDiComponent
      .rootPresentationDiModule
      .provideRootPage()
  }

  provideForbiddenPage(): React.ReactNode {
    return this
      .rootDiComponent
      .rootPresentationDiModule
      .provideForbiddenPage()
  }

  provideNotFoundPage(): React.ReactNode {
    return this
      .rootDiComponent
      .rootPresentationDiModule
      .provideNotFoundPage()
  }

  provideSubscriptionsListPage(): React.ReactNode {
    return this
      .subscriptionsDiComponent
      .subscriptionsPresentationDiModule
      .provideSubscriptionsPage()
  }

  provideSubscriptionPage(): React.ReactNode {
    return this
      .subscriptionsDiComponent
      .subscriptionsPresentationDiModule
      .provideSubscriptionPage()
  }
}

export function createDi(): Di {
  const coreI18nDiModule = new DefaultCoreI18nModule()

  const coreLocalDiModule = new DefaultCoreLocalDiModule({
    localStorageKeyPrefix
  })

  const coreNetworkDiModule: CoreNetworkDiModule = new DefaultCoreNetworkDiModule({
    coreLocalDiModule,
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL!
  })

  const coreSessionsNetworkSourceDiModule = new DefaultCoreSessionsNetworkDiModule({
    coreNetworkDiModule
  })

  const coreSubscriptionsNetworkSourceDiModule = new DefaultCoreSubscriptionsNetworkDiModule({
    coreNetworkDiModule
  })

  const coreCurrentUserNetworkSourceDiModule = new DefaultCoreCurrentUserNetworkDiModule({
    coreNetworkDiModule
  })

  const currentUserDataDiModule = new DefaultCurrentUserDataDiModule({
    currentUserNetworkDiModule: coreCurrentUserNetworkSourceDiModule
  })

  const currentUserDomainDiModule = new DefaultCurrentUserDomainDiModule({
    currentUserDataDiModule
  })

  const profileNetworkDiModule = new DefaultProfileNetworkDiModule({
    coreNetworkDiModule
  })

  const profileDataDiModule = new DefaultProfileDataDiModule({
    profileNetworkDiModule
  })

  const profileDomainDiModule = new DefaultProfileDomainDiModule({
    profileDataDiModule
  })

  const coreDataDiModule = new DefaultCoreDataDiModule({
    coreLocalDiModule,
    coreSubscriptionsNetworkSourceDiModule,
    coreCurrentUserNetworkSourceDiModule,
    coreSessionsNetworkSourceDiModule
  })

  const coreDomainDiModule = new DefaultCoreDomainDiModule({
    coreDataDiModule
  })

  const subscriptionsDomainDiModule = new DefaultSubscriptionsDomainDiModule({
    subscriptionsDataDiModule: new DefaultSubscriptionsDataDiModule({
      coreDataDiModule
    })
  })

  const objectsDataDiModule = new DefaultObjectsDataDiModule()

  const objectsDomainDiModule = new DefaultObjectsDomainDiModule({
    objectsDataDiModule
  })

  return new Di({
    coreDiComponent: new CoreDiComponent({
      coreI18nDiModule
    }),
    rootDiComponent: {
      rootPresentationDiModule: new DefaultRootPresentationDiModule({
        coreDomainDiModule,
        profileDomainDiModule,
        currentUserDomainDiModule
      })
    },
    subscriptionsDiComponent: {
      subscriptionsPresentationDiModule: new DefaultSubscriptionsPresentationDiModule({
        objectsDomainDiModule,
        coreI18nDiModule,
        subscriptionsDomainDiModule
      })
    }
  })
}
