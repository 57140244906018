import ProfileRepository from "../../domain/repositories/ProfileRepository"
import DiModule from "../../../lib/di/DiModule"
import DefaultProfileRepository from "../../data/repositories/DefaultProfileRepository"
import ProfileNetworkDiModule from "./network/ProfileNetworkDiModule"

export default interface ProfileDataDiModule {
  provideProfileRepository(): ProfileRepository
}

export class DefaultProfileDataDiModule extends DiModule implements ProfileDataDiModule {
  private readonly profileNetworkDiModule: ProfileNetworkDiModule

  constructor(parameters: {
    readonly profileNetworkDiModule: ProfileNetworkDiModule
  }) {
    super()
    this.profileNetworkDiModule = parameters.profileNetworkDiModule
  }

  provideProfileRepository(): ProfileRepository {
    return this.single(
      DefaultProfileRepository.name,
      () => new DefaultProfileRepository({
        profileNetworkSource: this.profileNetworkDiModule.provideProfileNetworkSource()
      })
    )
  }
}
