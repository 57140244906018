import autoBind from "auto-bind"
import Subscription from "../../../../app/domain/entities/Subscription"
import { StateObservable } from "../../../../lib/view-model/StateObservable"
import ViewModel from "../../../../lib/view-model/ViewModel"
import CancelSubscriptionUseCase from "../../../domain/use-cases/CancelSubscriptionUseCase"
import GetSubscriptionUseCase from "../../../domain/use-cases/GetSubscriptionUseCase"
import PurchaseSubscriptionUseCase from "../../../domain/use-cases/PurchaseSubscriptionUseCase"
import ObjectPresentationLogic from "../../../../objects/presentation/presentation-logics/ObjectPresentationLogic"
import { ObjectViewState } from "../../../../objects/presentation/view-states/ObjectViewState"
import UpdateCurrentUserUseCase from "../../../../app/domain/use-cases/current-user/UpdateCurrentUserUseCase"
import isBlank from "../../../../lib/isBlank"
import assertNever from "../../../../lib/assertNever"
import ExecutionError from "../../../../app/domain/entities/ExecutionError"
import ApplicationException from "../../../../app/domain/exceptions/ApplicationException"
import BroadcastObjectsEventUseCase from "../../../../objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"

export default class SubscriptionViewModel extends ViewModel {
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getSubscriptionUseCase: GetSubscriptionUseCase
  private readonly purchaseSubscriptionUseCase: PurchaseSubscriptionUseCase
  private readonly cancelSubscriptionUseCase: CancelSubscriptionUseCase
  private readonly updateCurrentUserUseCase: UpdateCurrentUserUseCase
  private readonly subscriptionId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<Subscription>

  readonly observableObjectViewState: StateObservable<ObjectViewState<Subscription>>

  constructor(parameters: {
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getSubscriptionUseCase: GetSubscriptionUseCase
    readonly purchaseSubscriptionUseCase: PurchaseSubscriptionUseCase
    readonly cancelSubscriptionUseCase: CancelSubscriptionUseCase
    readonly updateCurrentUserUseCase: UpdateCurrentUserUseCase
    readonly subscriptionId?: number
  }) {
    super()
    autoBind(this)

    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getSubscriptionUseCase = parameters.getSubscriptionUseCase
    this.purchaseSubscriptionUseCase = parameters.purchaseSubscriptionUseCase
    this.cancelSubscriptionUseCase = parameters.cancelSubscriptionUseCase
    this.updateCurrentUserUseCase = parameters.updateCurrentUserUseCase
    this.subscriptionId = parameters.subscriptionId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
  }

  async onCancelSubscriptionHandler() {
    this.objectPresentationLogic.setLoadingWithContentViewState()

    const result = await this.cancelSubscriptionUseCase.call({
      id: this.subscriptionId!
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "updated" })
        this.setAndShowObject(result.data)
        break
      case "failure":
        this.objectPresentationLogic.setLoadingFailureObjectViewState(result)
        break
      case "error":
        this.objectPresentationLogic.setLoadingErrorObjectViewState(result)
        break
    }
  }

  async onPurchaseSubscriptionHandler() {
    this.objectPresentationLogic.setLoadingWithContentViewState()

    const result = await this.purchaseSubscriptionUseCase.call({
      id: this.subscriptionId!
    })

    switch (result.type) {
      case "success":
        this.objectPresentationLogic.setRedirectViewState({
          url: result.data.paymentUrl!
        })
        break
      case "error":
        this.objectPresentationLogic.setLoadingErrorObjectViewState(result)
        break
      case "failure":
        this.objectPresentationLogic.setLoadingFailureObjectViewState(result)
        break
      default:
        assertNever(result)
    }
  }

  async onSaveEmailHandler() {
    const viewState = this.observableObjectViewState.getValue()
    if (viewState.type !== "loaded") {
      return
    }

    const email = viewState.object.email

    if (isBlank(email)) {
      return
    }

    this.setAndShowLoadingWithContentViewState()

    const result = await this.updateCurrentUserUseCase.call({
      email
    })

    switch (result.type) {
      case "success":
        await this.objectPresentationLogic.loadAndShowObject().then()
        this.broadcastObjectsEventUseCase.call({ type: "updated" })
        break
      case "failure":
        this.setSavingEmailException({ exception: result.exception })
        break
      case "error":
        this.setSavingEmailError({ error: result.error })
        break
      default:
        assertNever(result)
    }
  }

  onEmailChangedHandler(value: string | null) {
    const viewState = this.observableObjectViewState.getValue()

    if (viewState.type !== "loaded") {
      return
    }

    this.setAndShowObject({
      ...viewState.object,
      email: value
    })
  }

  onBackButtonClickedHandler() {
    this.objectPresentationLogic.setListViewState()
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<Subscription> {
    return new ObjectPresentationLogic({
      isNewObject: false,
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      getObject: async() => {
        return await this.getSubscriptionUseCase.call({ id: this.subscriptionId! })
      }
    })
  }

  private setAndShowLoadingWithContentViewState() {
    this.objectPresentationLogic.setLoadingWithContentViewState()
  }

  private setAndShowObject(object: Subscription) {
    this.objectPresentationLogic.setAndShowObject({ object })
  }

  setSavingEmailError({ error }: { readonly error: ExecutionError }) {
    const viewState = this.observableObjectViewState.getValue()
    if (viewState.type !== "loaded" && viewState.type !== "loading_with_content") {
      return
    }

    this.observableObjectViewState.setValue({
      ...viewState,
      type: "loaded",
      changingError: error
    })
  }

  setSavingEmailException({ exception }: { readonly exception: ApplicationException }) {
    const viewState = this.observableObjectViewState.getValue()
    if (viewState.type !== "loaded" && viewState.type !== "loading_with_content") {
      return
    }

    this.observableObjectViewState.setValue({
      ...viewState,
      type: "loaded",
      changingException: exception
    })
  }
}
