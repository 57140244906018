import DiModule from "../../../lib/di/DiModule"
import SessionLocalSource from "../../data/sources/local/SessionLocalSource"
import BrowserKeyValueStorage from "../../data/storages/BrowserKeyValueStorage"
import KeyValueStorage from "../../data/storages/KeyValueStorage"

export default interface CoreLocalDiModule {
  provideSessionLocalSource(): SessionLocalSource

  provideKeyValueStorage(): KeyValueStorage
}

export class DefaultCoreLocalDiModule extends DiModule implements CoreLocalDiModule {
  private readonly localStorageKeyPrefix: string

  constructor(parameters: {
    readonly localStorageKeyPrefix: string
  }) {
    super()
    this.localStorageKeyPrefix = parameters.localStorageKeyPrefix
  }

  provideSessionLocalSource(): SessionLocalSource {
    return this.single(
      SessionLocalSource.name,
      () => new SessionLocalSource({
        keyValueStorage: this.provideKeyValueStorage()
      })
    )
  }

  provideKeyValueStorage(): KeyValueStorage {
    return this.single(
      BrowserKeyValueStorage.name,
      () => new BrowserKeyValueStorage({
        keyPrefix: this.localStorageKeyPrefix
      })
    )
  }
}
