import React from "react"
import styles from "./PageHeaderComponent.module.scss"
import typography from "../../../../../ui_kit/typography.module.scss"
import { ReactComponent as IcPageHeader } from "../../../../../resources/icons/icPageHeader.svg"
import { useCoreTextProvider } from "../../../../../app/presentation/contexts/CoreTextProviderContext"
import User from "../../../../../app/domain/entities/User"
import { filterNotEmpty } from "../../../../../lib/filterNotEmpty"

export interface PageHeaderComponentProps {
  readonly user: User
}

export default function PageHeaderComponent({
  user
}: PageHeaderComponentProps) {
  const textProvider = useCoreTextProvider()

  const fullName =  filterNotEmpty([
    user.profile?.firstName,
    user.profile?.lastName
  ]).join(" ")

  return (
    <div className={styles.root}>
      <IcPageHeader />
      <div className={styles.accountInfoContainer}>
        <div className={styles.accountData}>
          <div className={`${styles.accountLabel} ${typography.subtitle2}`}>
            {textProvider.account()}
          </div>
          <div className={`${styles.accountName} ${typography.body2}`}>
            {fullName}
          </div>
        </div>
        {user.profile?.iconUrl && (
          <div
            className={styles.accountImage}
            style={{ backgroundImage: `url(${user.profile!.iconUrl!})` }}
          />
        )}
      </div>
    </div>
  )
}
