import React from "react"
import styles from "./NotFoundComponent.module.scss"

export default function NotFoundComponent() {
  return (
    <div className={styles.content}>
      <div className={styles.page}>
        Page not found
      </div>
    </div>
  )
}
