import React, { useRef, useState } from "react"
import Subscription from "../../../../app/domain/entities/Subscription"
import { StateObservable, useStateObservable } from "../../../../lib/view-model/StateObservable"
import styles from "./SubscriptionComponent.module.scss"
import { ObjectViewState } from "../../../../objects/presentation/view-states/ObjectViewState"
import ErrorMessageComponent from "../../../../lib/error-message/ErrorMessageComponent"
import { TextStyle } from "../../../../lib/text/TextComponent"
import ContentLoadingComponent from "../../../../app/presentation/components/content-loading/ContentLoadingComponent"
import assertNever from "../../../../lib/assertNever"
import { useNavigate } from "react-router-dom"
import ButtonComponent from "../../../../lib/button/ButtonComponent"
import TextInputComponent, { TextInputType } from "../../../../lib/text-input/TextInputComponent"
import BackComponent from "../../../../lib/BackComponent/BackComponent"
import typography from "../../../../ui_kit/typography.module.scss"
import { useCoreTextProvider } from "../../../../app/presentation/contexts/CoreTextProviderContext"
import TextButtonComponent from "../../../../lib/TextButtonComponent/TextButtonComponent"
import isPresent from "../../../../lib/isPresent"
import creditCardType from "credit-card-type"
import isBlank from "../../../../lib/isBlank"
import { ReactComponent as ImgVisa } from "../../../../resources/images/img_visa.svg"
import { ReactComponent as ImgJcb } from "../../../../resources/images/img_jcb.svg"
import { ReactComponent as ImgMaestro } from "../../../../resources/images/img_maestro.svg"
import { ReactComponent as ImgMasterCard } from "../../../../resources/images/img_mastercard.svg"
import { ReactComponent as ImgMir } from "../../../../resources/images/img_mir.svg"
import { ReactComponent as ImgUnionpay } from "../../../../resources/images/img_unionpay.svg"
import { ReactComponent as IcCheckboxChecked } from "../../../../resources/icons/ic_checkbox_checked.svg"
import { ReactComponent as IcCheckboxUnchecked } from "../../../../resources/icons/ic_checkbox_unchecked.svg"
import ModalComponent from "../../../../lib/ModalComponent/ModalComponent"

const checkboxSide = 24

const subscriptionAutoProlongationEnabled = process.env.REACT_APP_SUBSCRIPTION_AUTO_PROLONGATION_ENABLED === "true"

export interface SubscriptionComponentProps {
  readonly observableObjectViewState: StateObservable<ObjectViewState<Subscription>>
  readonly onCancelSubscriptionClicked: () => void
  readonly onPurchaseSubscriptionClicked: () => void
  readonly onSaveEmailClicked: () => void
  readonly onBackButtonClicked: () => void
  readonly onEmailChangedHandler: (email: string | null) => void
}

export default function SubscriptionComponent({
  observableObjectViewState,
  onCancelSubscriptionClicked,
  onPurchaseSubscriptionClicked,
  onSaveEmailClicked,
  onEmailChangedHandler,
  onBackButtonClicked
}: SubscriptionComponentProps) {
  const navigate = useNavigate()
  const textProvider = useCoreTextProvider()
  const objectViewState: ObjectViewState<Subscription> = useStateObservable(observableObjectViewState)
  const [isModalOpen, setModalOpen] = useState(false)
  const pageContent = useRef<HTMLDivElement>(null)
  const [agreementAccepted, setAgreementAccepted] = useState(!subscriptionAutoProlongationEnabled)
  const [subscriptionAccepted, setSubscriptionAccepted] = useState(!subscriptionAutoProlongationEnabled)

  const onRequestCancelSubscriptionClicked = () => {
    setModalOpen(true)
  }

  if (objectViewState.type === "initial") {
    return <></>
  }

  return (() => {
    switch (objectViewState.type) {
      case "loading":
        return (
          <div className={styles.loaderWrapper}>
            <ContentLoadingComponent />
          </div>
        )
      case "loading_error":
        return (
          <ErrorMessageComponent
            text={objectViewState.error.message}
            messageTextStyle={TextStyle.LABEL1_PRIMARY}
            buttonTextStyle={TextStyle.HEADER1_PRIMARY}
          />
        )
      case "loading_failure":
        return (
          <ErrorMessageComponent
            text={objectViewState.exception.message}
            messageTextStyle={TextStyle.LABEL1_PRIMARY}
            buttonTextStyle={TextStyle.HEADER1_PRIMARY}
          />
        )
      case "loading_with_content":
      case "loaded": {
        const error = objectViewState.changingError
        const exception = objectViewState.changingException

        const subscription = objectViewState.object
        const cardType = isPresent(subscription.bankCardPan) ?
          creditCardType(subscription.bankCardPan)[0] :
          undefined

        const cardLogo = (() => {
          if (isBlank(cardType)) return <div className={styles.logo} />

          switch (cardType.type) {
            case "visa":
              return (
                <ImgVisa className={styles.logo} />
              )
            case "mastercard":
              return (
                <ImgMasterCard className={styles.logo} />
              )
            case "jcb":
              return (
                <ImgJcb className={styles.logo} />
              )
            case "unionpay":
              return (
                <ImgUnionpay className={styles.logo} />
              )
            case "maestro":
              return (
                <ImgMaestro className={styles.logo} />
              )
            case "mir":
              return (
                <ImgMir className={styles.logo} />
              )
            default:
              return <div className={styles.logo} />
          }
        })()

        const backCardPanComponents: string[] | null | undefined = subscription.bankCardPan?.match(/.{1,4}/g)

        return (
          <div ref={pageContent}>
            <div className={styles.pageContent}>
              <BackComponent onBackButtonClicked={onBackButtonClicked} />
              <div className={styles.subscriptionContent}>
                <div className={styles.titleContainer}>
                  {subscription.chat?.chatCoverUrl && (
                    <div
                      className={styles.subscriptionImage}
                      style={{ backgroundImage: `url(${subscription.chat!.chatCoverUrl!})` }}
                    />
                  )}
                  <div className={`${styles.subscriptionTitle} ${typography.title4}`}>
                    {subscription.chat?.name}
                  </div>
                </div>

                <div className={styles.separator} />

                {!subscription.needsEmail && (
                  <div className={`${styles.subscriptionPrice} ${typography.title1}`}>
                    {textProvider.subscriptionPrice({
                      price: subscription.chat?.subscriptionPrice
                    })}
                  </div>
                )}

                <div className={`${styles.subscriptionDescription} ${typography.body2}`}>
                  {subscription.statusExplanation}
                </div>
                {subscription.canPurchase && subscriptionAutoProlongationEnabled && (
                  <>
                    <label className={`${styles.agreementContainer} ${typography.body2}`}>
                      <input
                        type="checkbox"
                        checked={subscriptionAccepted}
                        onChange={() => {
                          setSubscriptionAccepted(!subscriptionAccepted)
                        }}
                      />
                      {subscriptionAccepted && (
                        <IcCheckboxChecked
                          width={checkboxSide}
                          height={checkboxSide}
                        />
                      )}
                      {!subscriptionAccepted && (
                        <IcCheckboxUnchecked
                          width={checkboxSide}
                          height={checkboxSide}
                        />
                      )}
                      <div className={styles.textContainer}>
                        {textProvider.subscriptionText({
                          price: subscription.chat?.subscriptionPrice
                        })}
                      </div>
                    </label>
                    <label className={`${styles.agreementContainer} ${typography.body2}`}>
                      <input
                        type="checkbox"
                        checked={agreementAccepted}
                        onChange={() => {
                          setAgreementAccepted(!agreementAccepted)
                        }}
                      />
                      {agreementAccepted && (
                        <IcCheckboxChecked
                          width={checkboxSide}
                          height={checkboxSide}
                        />
                      )}
                      {!agreementAccepted && (
                        <IcCheckboxUnchecked
                          width={checkboxSide}
                          height={checkboxSide}
                        />
                      )}
                      <div className={styles.textContainer}>
                        {textProvider.agreementText()}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={textProvider.subscriptionOfferUrl()}
                        >
                          {textProvider.subscriptionOfferAgreementText()}
                        </a>
                        {textProvider.and()}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={textProvider.privacyPolicyUrl()}
                        >
                          {textProvider.privacyPolicyAgreementText()}
                        </a>
                      </div>
                    </label>
                  </>
                )}
                {subscription.needsEmail && (
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                      onSaveEmailClicked()
                    }}
                  >
                    <TextInputComponent
                      value={subscription.email}
                      type={TextInputType.EMAIL}
                      onChange={onEmailChangedHandler}
                      className={styles.emailInput}
                      hasErrors={
                        isPresent(error) ||
                        isPresent(exception)
                      }
                    />
                    {isPresent(exception) || isPresent(error) && (
                      <div className={`${styles.errorsMessage} ${typography.body1}`}>
                        <>
                          {error.message}
                          {exception}
                        </>
                      </div>
                    )}
                    <TextButtonComponent
                      onClick={onSaveEmailClicked}
                      title={textProvider.setEmail()}
                      className={`${styles.submitButton} ${styles.text}`}
                    />
                  </form>
                )}
                {subscription.autoProlongation && backCardPanComponents && (
                  <div className={styles.cardInfo}>
                    {cardLogo}
                    <div className={`${styles.number} ${typography.body2}`}>
                      {`${backCardPanComponents[2]} ${backCardPanComponents[3]}`}
                    </div>
                  </div>
                )}
                {subscription.canPurchase && (
                  <ButtonComponent
                    onClick={onPurchaseSubscriptionClicked}
                    title={textProvider.purchaseSubscription()}
                    isDisabled={!agreementAccepted || !subscriptionAccepted}
                    className={styles.submitButton}
                  />
                )}
                {subscription.canSuspend && (
                  <TextButtonComponent
                    onClick={onRequestCancelSubscriptionClicked}
                    title={textProvider.cancelSubscription()}
                    className={`${styles.submitButton} ${styles.text}`}
                  />
                )}
              </div>
              {objectViewState.type === "loading_with_content" && (
                <div className={styles.loaderWrapper}>
                  <ContentLoadingComponent />
                </div>
              )}
            </div>
            {pageContent.current && (
              <ModalComponent
                appElement={pageContent.current}
                isOpen={isModalOpen}
                title={textProvider.cancelSubscriptionTitle()}
                onRequestClosed={() => {
                  setModalOpen(false)
                }}
                positiveButtonTitle={textProvider.yes()}
                onPositiveButtonClicked={() => {
                  setModalOpen(false)
                  onCancelSubscriptionClicked()
                }}
                negativeButtonTitle={textProvider.no()}
                onNegativeButtonClicked={() => {
                  setModalOpen(false)
                }}
              />
            )}
          </div>
        )
      }
      case "list":
        navigate(-1)
        return <></>
      case "redirect":
        setTimeout(() => {
          window.open(objectViewState.url, "_top")
        }, 0)

        return (
          <div className={styles.loaderWrapper}>
            <ContentLoadingComponent />
          </div>
        )
      default:
        assertNever(objectViewState)
    }
  })()
}
