import { Expose } from "class-transformer"
import AttributeError from "../../../../domain/entities/AttributeError"

export default class NetworkAttributeError implements AttributeError {
  @Expose()
  readonly type?: string | null

  @Expose()
  readonly message?: string | null
}
