import DiModule from "../../../lib/di/DiModule"
import CoreNetworkDiModule from "./CoreNetworkDiModule"
import CurrentUserNetworkSource from "../../data/sources/network/CurrentUserNetworkSource"
import DefaultCurrentUserNetworkSource from "../../data/sources/network/DefaultCurrentUserNetworkSource"

export default interface CoreCurrentUserNetworkDiModule {
  provideCurrentUserNetworkSource(): CurrentUserNetworkSource
}

export class DefaultCoreCurrentUserNetworkDiModule
  extends DiModule
  implements CoreCurrentUserNetworkDiModule {

  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  provideCurrentUserNetworkSource(): CurrentUserNetworkSource {
    return this.single(
      DefaultCurrentUserNetworkSource.name,
      () => new DefaultCurrentUserNetworkSource({
        backendHttpClient: this.coreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
