import React, { ReactElement } from "react"
import RootPage from "../../presentation/pages/root/RootPage"
import RootViewModel from "../../presentation/pages/root/RootViewModel"
import DiModule from "../../../lib/di/DiModule"
import CoreDomainDiModule from "../../../app/di/modules/CoreDomainDiModule"
import NotFoundPage from "../../presentation/pages/not_found/NotFoundPage"
import ForbiddenPage from "../../presentation/pages/forbidden/ForbiddenPage"
import CurrentUserDomainDiModule from "../../../app/di/modules/CurrentUserDomainDiModule"
import ProfileDomainDiModule from "../../../app/di/modules/ProfileDomainDiModule"

export default interface RootPresentationDiModule {
  provideRootPage(): ReactElement

  provideRootViewModel({
    authToken
  }: {
    readonly authToken: string | null | undefined
  }): RootViewModel

  provideNotFoundPage(): ReactElement

  provideForbiddenPage(): ReactElement
}

export class DefaultRootPresentationDiModule extends DiModule implements RootPresentationDiModule {
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly profileDomainDiModule: ProfileDomainDiModule
  private readonly currentUserDomainDiModule: CurrentUserDomainDiModule

  constructor(parameters: {
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly profileDomainDiModule: ProfileDomainDiModule
    readonly currentUserDomainDiModule: CurrentUserDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.profileDomainDiModule = parameters.profileDomainDiModule
    this.currentUserDomainDiModule = parameters.currentUserDomainDiModule
  }

  provideRootPage(): ReactElement {
    return <RootPage provideViewModel={(parameters) => this.provideRootViewModel(parameters)} />
  }

  provideRootViewModel({
    authToken
  }: {
    readonly authToken: string | null | undefined
  }): RootViewModel {
    return new RootViewModel({
      authToken,
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      createSessionUseCase: this.coreDomainDiModule.provideCreateSessionUseCase(),
      destroySessionUseCase: this.coreDomainDiModule.provideDestroySessionUseCase(),
      getCurrentUserUseCase: this.currentUserDomainDiModule.provideGetCurrentUserUseCase()
    })
  }

  provideNotFoundPage(): ReactElement {
    return <NotFoundPage />
  }

  provideForbiddenPage(): ReactElement {
    return <ForbiddenPage />
  }
}
