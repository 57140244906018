import { Expose, Type } from "class-transformer"
import NetworkFile from "../files/NetworkFile"

export default class NetworkImage {
  @Expose({ name: "id" })
  @Type(() => Number)
  readonly id?: number | null

  @Expose({ name: "file" })
  @Type(() => NetworkFile)
  readonly file?: NetworkFile | null
}
