export default class UrlProvider {
  buildRootUrl(): string {
    return this.buildUrl({ path: this.buildRootPath() })
  }

  buildProfileUrl(): string {
    return this.buildUrl({ path: this.buildProfilePath() })
  }

  buildSubscriptionsUrl(): string {
    return this.buildUrl({ path: this.buildSubscriptionsPath() })
  }

  buildSubscriptionUrl(parameters: { readonly id: string }): string {
    return this.buildUrl({ path: this.buildSubscriptionPath(parameters) })
  }

  buildForbiddenUrl(): string {
    return this.buildUrl({ path: this.buildForbiddenPath() })
  }

  buildForbiddenPath(): string {
    return "forbidden"
  }

  buildProfilePath(): string {
    return "profile"
  }

  buildSubscriptionPath({ id }: { readonly id: string }): string {
    return `${this.buildSubscriptionsPath()}/${id}`
  }

  buildSubscriptionsPath(): string {
    return "subscriptions"
  }

  buildRootPath(): string {
    return ""
  }

  private buildUrl({ path }: { readonly path: string }): string {
    return `/${path}`
  }
}
