export type TextProvidersMap<TextProvider> = { [key: string]: TextProvider }

export default class I18n<TextProvider> {
  private readonly textProviderByLanguage: TextProvidersMap<TextProvider>
  private readonly language: string

  constructor(parameters: {
    textProviderByLanguage: TextProvidersMap<TextProvider>
    language: string
  }) {
    this.textProviderByLanguage = parameters.textProviderByLanguage
    this.language = parameters.language
  }

  getTextProvider(): TextProvider {
    return this.textProviderByLanguage[this.language]
  }
}
