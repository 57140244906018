import { Expose, Type } from "class-transformer"

export default class NetworkChat {
  @Expose({ name: "id" })
  @Type(() => Number)
  readonly id?: number | null

  @Expose({ name: "name" })
  @Type(() => String)
  readonly name?: string | null

  @Expose({ name: "communication_type" })
  @Type(() => String)
  readonly communicationType?: string | null

  @Expose({ name: "subscription_price" })
  @Type(() => String)
  readonly subscriptionPrice?: string | null

  @Expose({ name: "chat_cover" })
  @Type(() => NetworkChatCover)
  readonly chatCover?: NetworkChatCover | null
}

class NetworkChatCover {
  @Expose({ name: "file" })
  @Type(() => NetworkFile)
  readonly file?: NetworkFile | null
}

class NetworkFile {
  @Expose({ name: "urls" })
  @Type(() => NetworkFileUrls)
  readonly urls?: NetworkFileUrls | null

  @Expose({ name: "dimensions" })
  @Type(() => NetworkFileDimensions)
  readonly dimensions?: NetworkFileDimensions | null
}

class NetworkFileUrls {
  @Expose({ name: "banner" })
  @Type(() => String)
  readonly banner?: string | null

  @Expose({ name: "icon" })
  @Type(() => String)
  readonly icon?: string | null
}

class NetworkFileDimensions {
  @Expose({ name: "width" })
  @Type(() => Number)
  readonly width?: number | null

  @Expose({ name: "height" })
  @Type(() => Number)
  readonly height?: number | null
}
