import React from "react"
import { Outlet } from "react-router-dom"
import styles from "./RootComponent.module.scss"
import { LocationContextProvider } from "../../../../lib/view-model/useLocationContextProvider"
import { LocationContext } from "../../../../lib/view-model/useLocationContext"
import User from "../../../../app/domain/entities/User"
import PageHeaderComponent from "./PageHeaderComponent/PageHeaderComponent"
import PageFooterComponent from "./PageFooterComponent/PageFooterComponent"

interface Props {
  // TODO: readonly onLogoutClicked: () => void
  readonly locationContextProvider: LocationContextProvider
  readonly user: User | undefined | null
}

export default function RootComponent({
  // TODO: onLogoutClicked
  user,
  locationContextProvider
}: Props) {
  return (
    <>
      <div className={styles.root}>
        {user && (
          <PageHeaderComponent user={user} />
        )}
        {/* TODO: header with profile info */}
        <div className={styles.page}>
          {locationContextProvider.locationId && (
            <Outlet
              context={{
                locationId: locationContextProvider.locationId,
                getOrCreateViewModel: locationContextProvider.getOrCreateViewModel
              } as LocationContext}
            />
          )}
        </div>
        <PageFooterComponent />
      </div>
    </>
  )
}
